:root {
    --font-primary: "DM Sans", sans-serif;

    /* Colors */
    --white: #fff;
    --white-light: rgba(255, 255, 255, 0.7);
    --black: #2e323e;
    --dark: #767b89;
    --blue: #4c76eb;
    --blue-hover: #608aff;
    --blue-active: #749eff;
    --blue-focus: #6a94ff;
    --blue-bg: #fffdfa;
    --gray: #e1e1e1;
    --gray-2: #cdcdcd;
    --gray-dark: #b8babe;
    --gray-light: #dcdce5;
    --gray-bg: #f9fafb;
    --gray-bg-dark: #f3f4f5;
    --gray-70: #ececec;
    --green: #98db96;
    --green-second: #c8fac7;
    --green-third: #7abd78;
    --orange: #df8b0e;
    --red: #e26464;

    /* Transition */
    --transition: 0.3s;
}
