.result-title {
    margin-bottom: 1rem;
    padding: 0 1rem;
}

.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 39.625rem;
    margin: 0 auto;
    padding-top: 2.5rem;
}

.footer .divider {
    margin: 0 1.5rem;
}

@media screen and (width <= 768px) {
    .footer {
        flex-direction: column;
    }

    .footer .cta {
        width: 100%;
    }

    .footer .divider {
        margin: 0.5rem;
    }
}
