@import '../../sass/styles.scss';

.hero {
    padding-bottom: 206px;
    color: $white;
    position: relative;

    @include max-w-sm {
        padding-bottom: 140px;

        & :global(.nav-holder) {
            background-color: $blue;
        }
    }

    @include max-w-lg {
        & :global(.nav-holder) {
            background-color: $blue;
        }
    }
}

.subtitle {
    @include text-md;
    color: $white-light;
    margin: 24px 0 40px 0;

    @include max-w-sm {
        @include text-small;
        margin: 12px 0 24px 0;
    }
}

.holder {
    padding-top: 80px;
    padding-bottom: 80px;

    @include max-w-sm {
        padding-top: 40px;
        padding-bottom: 40px;
    }
}

.content {
    text-align: center;
}

.previewStroke {
    position: absolute;
    pointer-events: none;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    position: absolute;
}

.previewStrokeContainer {
    position: absolute;
    bottom: 200px;
    left: 50%;
    transform: translateX(-50%);

    @include max-w-sm {
        margin-top: -136px;
    }
}

.previewWrap {
    margin-top: -206px;
    position: relative;

    @include max-w-sm {
        margin-top: -140px;
    }
}

.preview {
    position: relative;
    display: flex;
    align-items: flex-end;
    border-radius: calc(32px - 6px);
    overflow: hidden;
    padding: 100px 50px 50px;
    min-height: 440px;
    color: $white;

    @include max-w-sm {
        border-radius: calc(16px - 4px);
    }

    @include max-w-sm {
        min-height: 300px;
        padding: 56px 24px 24px;
    }

    & h2 {
        color: $white;
        font-weight: 700;

        @include max-w-sm {
            font-size: 20px;
            line-height: 150%;
        }
    }

    @media (any-hover: hover) {
        &:hover {
            text-decoration: underline;
        }
    }
}

.previewContainer {
    padding: 6px;
    margin: -6px;
    border-radius: 32px;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0.15);

    @include max-w-sm {
        border-radius: 16px;
        padding: 4px;
        margin: -6px 0;
    }
}

.previewContent {
    max-width: 530px;
    width: 100%;
    position: relative;
}

.image {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    &::before {
        content: '';
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.35) 100%);

        @include max-w-sm {
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 85.94%);
        }
    }

    & > img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.date {
    @include helper-text;
    color: $white-light;
    margin-bottom: 8px;

    @include max-w-sm {
        @include text-small;
    }
}
