@import '../../../../../../sass/styles.scss';

.wrap {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1.25rem;

    @include max-w-prev-xs {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}
