@import '../../sass/styles.scss';

.section {
    padding: 80px 0 60px;

    @include max-w-sm {
        padding: 40px 0;
    }
}

.holder {
    display: grid;
    gap: 22px;
    grid-template-columns: repeat(2, minmax(0, 1fr));

    @include max-w-sm {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}

.image {
    & img {
        max-width: 100%;
    }
}

.contentWrap {
    display: flex;
    align-items: center;
    justify-content: center;

    @include max-w-sm {
        all: revert;
    }
}

.content {
    max-width: 480px;
    width: 100%;

    @include max-w-sm {
        max-width: none;
    }
}

.subtitle {
    @include helper-text;
    margin: 16px 0 0 0;

    @include max-w-sm {
        @include text-small;
        margin: 12px 0 0 0;
    }
}

.quantity {
    @include numbers-small;

    & span {
        color: $blue;
    }

    @include max-w-sm {
        @include numbers-xs;
    }
}

.helperText {
    @include text-small;
    color: $dark;
}

.values {
    margin-top: 24px;
    display: grid;
    gap: 12px;
    grid-template-columns: repeat(3, minmax(0, 1fr));

    @include max-w-sm {
        margin-top: 16px;
    }
}
