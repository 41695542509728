@import '../../../sass/styles.scss';

.btn {
    width: 40px;
    height: 40px;
    border-radius: 14px;
    background-color: $gray-bg-dark;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    color: $gray-dark;
    transition: background-color $transition;

    & svg {
        width: 32px;
        height: 32px;
        flex-shrink: 0;
        fill: currentColor;
    }

    &:not(:global(.swiper-button-disabled)):hover {
        background-color: #eff0f1;
    }

    &:not(:global(.swiper-button-disabled)):active {
        background-color: #e9eaeb;
    }

    &:global(.swiper-button-disabled) {
        opacity: 0.5;
        cursor: not-allowed !important;

        & * {
            pointer-events: none !important;
        }
    }

    &.sm {
        width: 24px;
        height: 24px;
        border-radius: 8px;

        & svg {
            width: 24px;
            height: 24px;
        }
    }
}

.watchOverflow:global(.swiper-button-lock) {
    display: none;
}

.reverse svg {
    transform: scale(-1);
}
