@import '../../sass/styles.scss';

.section {
    padding: 100px 0;

    @include max-w-sm {
        padding: 32px 0 40px;
    }
}

.wrap {
    padding-top: 154px;
    padding-bottom: 42px;

    @include max-w-sm {
        padding-top: 130px;
        padding-bottom: 34px;
    }
}

.subtitle {
    @include helper-text;
    margin: 16px 0 24px 0;

    @include max-w-sm {
        @include text-small;
        margin: 12px 0 24px 0;
    }
}
