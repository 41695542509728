@import '../../sass/styles.scss';

.title {
    @include h1;
    font-weight: 400;
}

.subtitle {
    @include helper-text;
}

.doubleSections {
    &:not(:empty) {
        margin-top: 20px;
        margin-bottom: 20px;

        @include max-w-sm {
            margin-top: 0;
            margin-bottom: 0;
        }
    }
}

.top-content-image {
    margin-right: -2rem;
}

.accordion {
    padding: 50px 0 100px 0;

    & .wrapper {
        max-width: 1150px;
        margin: 0 auto;
    }

    & h2 {
        text-align: center;
    }

    & .subtitle {
        @include helper-text;
        margin: 16px auto 40px auto;
        text-align: center;
        max-width: 580px;
    }

    & .link {
        color: $blue;
        transition: all $transition;

        &:hover {
            color: $green;
        }
    }

    & .content {
        max-width: 780px;
        margin: 0 auto;
    }
}

.cta {
    border-radius: 0.5rem;
}

.cta svg {
    width: revert;
    height: revert;
}

@media (max-width: 1150px) {
    .presentation {
        padding: 0 10px;

        & .right {
            padding: 122px 0 100px 10px;
        }
    }

    .accordion {
        padding: 50px 15px 100px 15px;
    }
}

@media (max-width: 650px) {
    .accordion {
        padding: 20px 15px 40px 15px;

        & .subtitle {
            @include text-small;
            margin: 12px auto 32px auto;
            max-width: 300px;
        }
    }
}
