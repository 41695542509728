@import '../../sass/styles.scss';

.debtTypes {
    @include gray-bg;

    @include max-w-sm {
        padding-top: 32px;
        padding-bottom: 32px;
    }
}

.subtitle {
    @include helper-text;
    margin: 16px 0 40px;

    @include max-w-xs {
        margin: 12px 0 24px;
        @include text-small;
    }
}

.holder {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    max-width: 1072px;

    @include max-w-sm {
        grid-template-columns: minmax(0, 1fr);
        max-width: none;
    }
}

.item {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 18px 3%;

    @include max-w-sm {
        gap: 16px;
    }

    &:not(.checked) {
        padding-top: 2px;
    }

    &:first-child {
        padding-right: 11.941%;

        @include max-w-sm {
            padding-right: 0;
            padding-bottom: 24px;
        }
    }

    &:last-child {
        padding-right: 0;

        @include max-w-sm {
            padding-bottom: 0;
        }
    }

    &:nth-child(n + 2) {
        padding-left: 11.941%;
        border-left: 1px solid $gray;

        @include max-w-sm {
            padding-top: 24px;
            padding-left: 0;
            border-left: none;
            border-top: 1px solid $gray;
        }
    }

    &.checked {
        gap: 16px 6%;

        @include max-w-sm {
            gap: 16px;
        }
    }
}
