@import '../../../../sass/styles.scss';


.subtitle {
    margin: 24px 0 48px 0;

    & * {
        color: $dark;
        font-weight: 400;

        @include max-w-sm {
            @include text-small;
        }
    }

    @include max-w-xs {
        margin: 12px 0 24px 0;
    }
}

.holder {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 16px 20px;

    @include max-w-sm {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

.card {
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 320px;
    width: 100%;
    margin: 0 auto;

    @include max-w-sm {
        align-items: flex-start;
        max-width: none;
        margin: 0;
    }
}

.icon {
    flex-shrink: 0;
    width: 48px;
    height: 48px;

    @include max-w-sm {
        width: 32px;
        height: 32px;
    }
}

.cardHeader {
    @include max-w-sm {
        display: flex;
        align-items: center;
    }
}

.cardTitle {
    margin-top: 2px;

    & * {
        font-weight: 700;
    }

    @include max-w-sm {
        @include text-icon;
        margin: 0;
        margin-left: 6px;
    }

    & span {
        color: $green;
    }
}

.cardDesc {
    margin-top: 8px;

    & * {
        color: $dark;
        font-weight: 400;

        @include max-w-sm {
            @include text-small;
        }
    }
}

.action {
    margin-top: 48px;

    @include max-w-sm {
        margin-top: 24px;
    }
}

.helperText {
    @include descriptors;
    color: $dark;
    margin-top: 12px;
}

.basement {
    @include descriptors;
    color: $dark;
    max-width: 780px;
    margin: 40px auto 0;

    @include max-w-sm {
        margin: 40px 0 0;
        max-width: none;
    }
}
