.wrap {
    font-family: "DM Sans", sans-serif;
}

.heading-h1 {
    font-size: 3.75rem !important;
    line-height: 4.5rem !important;
}

.heading-h1-md {
    font-size: 2.5rem !important;
    line-height: 2.5rem !important;
}

.heading-h1-sm {
    font-size: 1.875rem !important;
    line-height: 2.5rem !important;
}

.heading-h2 {
    font-size: 2rem !important;
    line-height: 2.75rem !important;
    font-weight: 400 !important;
}

.heading-h3 {
    font-size: 1.5rem !important;
    line-height: 2.125rem !important;
}

.heading-h4 {
    font-size: 1.125rem !important;
    line-height: 1.875rem !important;
}

.button-text {
    font-weight: 500 !important;
    font-size: 1rem !important;
    line-height: 150% !important;
}

.text-md {
    font-weight: 400 !important;
    font-size: 1.125rem !important;
    line-height: 1.875rem !important;
}

.text-small {
    font-weight: 400 !important;
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
}

.text-section {
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
}

.text-section-sm {
    font-size: 0.875rem !important;
    line-height: 1.125rem !important;
}

.text-icon {
    font-weight: 700 !important;
    font-size: 1rem !important;
    line-height: 1.5rem !important;
}

.helper-text {
    font-weight: 400 !important;
    font-size: 1rem !important;
    line-height: 1.5rem !important;
    color: #767b89 !important;
}

.descriptors {
    font-weight: 400 !important;
    font-size: 0.75rem !important;
    line-height: 1.125rem !important;
}

.numbers {
    font-weight: 500 !important;
    font-size: 2.75rem !important;
    line-height: 3.5rem !important;
}

.numbers-small {
    font-weight: 500 !important;
    font-size: 2.125rem !important;
    line-height: 2.75rem !important;
}

.numbers-xs {
    font-weight: 500 !important;
    font-size: 1.875rem !important;
    line-height: 2.5rem !important;
}
