@import '../../../../sass/styles.scss';

.section {
    text-align: initial;

    @include max-w-sm {
        padding-top: 40px;
    }
}

.sectionHolder {
    padding-top: 50px;

    @include max-w-sm {
        padding-top: 40px;
    }
}

.subtitle {
    margin: 24px 0 48px 0;

    & * {
        color: $dark;
        font-weight: 400;

        @include max-w-sm {
            @include text-small;
        }
    }

    @include max-w-xs {
        margin: 12px 0 24px 0;
    }

    &.offsetTopXs {
        margin: 10px 0 30px 0;

        @include max-w-xs {
            margin: 10px 0 24px 0;
        }
    }
}

.offsetTopXs {
    margin-top: 10px;
}

.holder {
    max-width: 580px;
    width: 100%;

    & :global(.swiper-slide:not(.swiper-slide-active)) {
        & .item {
            display: none;
        }
    }
}

.item {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 20px;

    @include max-w-prev-xs {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}

.actions {
    margin-top: 48px;
    display: flex;
    align-items: center;

    @include max-w-sm {
        margin-top: 24px;
    }

    & > * {
        &:nth-child(n + 2) {
            margin-left: 16px;
        }
    }
}

.inner {
    padding-top: 72px;
    padding-bottom: 116px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.titleHolder {
    margin-top: 32px;
    text-align: center;
    max-width: 780px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    & .subtitle {
        margin: 12px 0 0;
    }
}

.image {
    max-width: 80px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    & img {
        display: block;
        max-width: 100%;
        height: auto;
    }

    & + .titleHolder {
        margin-top: 48px;
    }
}

.action {
    margin-top: 24px;
}

.wrap {
    margin-top: 42px;
    margin-bottom: 150px;

    @include max-w-prev-xs {
        margin-top: 0;
        margin-bottom: 80px;
    }

    & h1 {
        max-width: 600px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    & .action {
        margin-top: 40px;
    }
}

.sectionSuccess {
    & .action {
        margin-top: 32px;
    }
}

.slider {
    max-width: 1318px;
    width: 100%;
    margin: 0 auto;
    overflow: visible;
    padding-left: min(69px, 5.05vw);
    padding-right: min(69px, 5.05vw);

    @include max-w-xs {
        padding-left: 16px;
        padding-right: 16px;
    }
}

.sliderHoler {
    overflow: hidden;
}

.sliderTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.slide {
    width: auto;

    &:global(.swiper-slide:not(.swiper-slide-visible)) .sliderRadio input[type='radio']:not(:checked) ~ * {
        opacity: 0.3;
    }
}

.sliderRadio {
    min-width: 226px;
    width: 226px;
    display: flex;
}

.sliderRadio input[type='radio'] ~ * {
    will-change: opacity;
    transition: opacity $transition;
}

.sliderTitle {
    margin-bottom: 12px;
}

.subtitleSecond {
    color: $dark;
    font-weight: 400;
    line-height: 150%;

    @include max-w-sm {
        @include text-small;
    }
}

.sliderControl > *:nth-child(n + 2) {
    margin-left: 4px;
}

.radioWrap {
    padding-top: 20px;
}

.radioItems {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 12px;
    margin-left: -6px;
    margin-right: -6px;
    margin-bottom: -12px;
}

.radioItem {
    width: 158px;
    flex-shrink: 0;
    margin-left: 6px;
    margin-right: 6px;
    margin-bottom: 12px;
}

.radioWrap .action {
    margin-top: 32px;
}

.sectionError .titleHolder {
    margin-top: 0;
    margin-bottom: 0;
}

.widthAlignment {
    width: 40%;
}

.score-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2.5rem;

    .card {
        display: flex;
        flex-direction: column;
        padding: 1.5rem;
        flex: 1 1 100%;
        max-width: 19rem;
        border-radius: 0.875rem;
        background-color: $white;

        .card-title {
            @include button-text;
            font-weight: 400;
        }

        .card-content {
            @include h2;
            font-weight: 700;
            color: $blue;
        }

        &.debt .card-content {
            color: $green-third;
        }
    }

    .card + .card {
        margin-left: 1.5rem;
    }
}

.actions-inner {
    display: flex;
    justify-content: center;
    align-items: center;

    .split {
        display: inline-block;
        margin: 0 1.5rem;
    }

    .call-now-cta a {
        min-width: 19.5rem;
        width: 100%;
        flex-grow: 1;
    }
}

@media (max-width: 991px) {
    .widthAlignment {
        width: 80%;
    }
}

@media (max-width: 600px) {
    .score-wrap {
        flex-direction: column;
    }

    .card {
        width: 100%;
        max-width: 100%;
    }

    .card + .card {
        margin-left: 0 !important;
        margin-top: 1.5rem;
    }
}

@media (max-width: 730px) {
    .actions-inner {
        flex-direction: column;
    }

    .call-now-cta {
        width: 80%;
    }

    .split {
        margin: 0.75rem 0 0 !important;
    }

    .call-now-cta a {
        min-width: 100% !important;
    }
}
