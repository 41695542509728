.wrap {
    display: flex;
    flex-direction: column;
    width: 20rem;
    max-width: 20rem;
}

.image {
    margin-bottom: 1.2rem;
}

.title {
    font-weight: 700;
    margin-bottom: 0.5rem;
}

.step {
    color: var(--dark);
    margin-bottom: 0.25rem;
}

.subtitle {
    color: #767b89;
}

.icon {
    flex-shrink: 0;
}

@media screen and (max-width: 767px) {
    .wrap {
        max-width: none;
        flex-direction: column;
        align-items: flex-start;
    }

    .service .icon {
        margin-bottom: 0.25rem;
    }
}
