@import '../../sass/styles.scss';

.whyTriton {
    @include gray-bg;

    @include max-w-sm {
        padding-top: 32px;
        padding-bottom: 32px;
    }
}

.tritonFocus {
    margin-top: 80px;
    padding: 20px 0 80px;

    @include max-w-sm {
        margin-top: 20px;
        padding: 20px 0 40px 0;
    }
}

.imageBlock {
    background-color: hsla(0, 0%, 0%, 0.35);
}
