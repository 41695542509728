@import "./variables.css";

:root {
    --ring-width: 2px;
    --ring-color: rgb(0, 126, 244);
    --ring-offset-shadow: 0 0 0 0 #ffffff;
    --ring-shadow: 0 0 0 var(--ring-width) var(--ring-color);
}

body .focus-primary:focus-visible {
    outline: 2px solid transparent;
    outline-offset: 2px;
    box-shadow:
        var(--ring-offset-shadow),
        var(--ring-shadow),
        0 0 #0000;
}

body .focus-primary:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
    box-shadow:
        var(--ring-offset-shadow),
        var(--ring-shadow),
        0 0 #0000;
}

body .focus-within-primary:focus-within {
    outline: 2px solid transparent;
    outline-offset: 2px;
    box-shadow:
        var(--ring-offset-shadow),
        var(--ring-shadow),
        0 0 #0000;
}

body .focus-primary:focus:not(body .focus-primary:focus-visible) {
    --ring-width: 0;
}

body .focus-primary-child {
    outline: 2px solid transparent;
    outline-offset: 2px;
    box-shadow:
        var(--ring-offset-shadow),
        var(--ring-shadow),
        0 0 #0000;
}

.visually-hidden {
    display: inline-block;
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border-width: 0;
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
