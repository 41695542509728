.wrap {
    flex: 1 1 100%;
    width: 100%;
    max-width: 19rem;
    margin: 0;
}

.inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1.5rem 0.875rem;
    border-radius: 0.875rem;
    background-color: var(--white);
}

.wrap .label {
    font-weight: 400 !important;
    text-align: center;
}

.wrap .value {
    color: var(--blue);
    font-weight: 700 !important;
    text-align: center;
    margin: 0;
}
