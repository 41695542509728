.titleHolder {
    margin-top: 32px;
    text-align: center;
    max-width: 780px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.subtitle {
    margin: 0.75rem 0 2rem;
}
