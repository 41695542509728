@import '../../../sass/styles.scss';

.holder {
    position: relative;
    display: inline-flex;
    align-items: center;
    overflow: hidden;
    border-radius: 14px;
    cursor: pointer;
}

.control {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 15.2px 20px;
    border-radius: 14px;
    color: $black;
    font-size: 16px;
    line-height: 150%;
    font-weight: 400;
    background-color: $white;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

.sm .control {
    padding: 12.7px 16px;
    font-size: 14px;
}

.iconWrap {
    margin-left: 6.67px;
    border-radius: 50%;
    width: 18.67px;
    height: 18.67px;
    position: relative;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before,
    &::after {
        content: '';
        box-sizing: border-box;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }

    &::before {
        background-color: transparent;
        border: 1.5px solid $gray-light;
    }

    &::after {
        transform: scale(0);
        background-color: $white;
        transition: transform 0.2s;
    }
}

.icon {
    stroke: $green;
    width: 0.7rem;
    height: 0.5rem;
    fill: none;
    stroke-dashoffset: 0;
    opacity: 0;
    position: relative;
    z-index: 2;
    stroke-dasharray: 0.7em;
    animation-duration: 0.3s;
    animation-timing-function: linear;
    transition: opacity 0.2s ease;
}

.holder input[type='radio']:checked ~ .control {
}

.holder input[type='radio'] {
    &:not([disabled]):hover ~ .control {
        border-color: $gray;
    }

    &:not([disabled]):focus-visible ~ .control,
    &:not([disabled]):focus ~ .control {
        border-color: $gray;
    }

    &:not([disabled]):focus {
        &:not(:focus-visible) ~ .control {
            border-color: $gray;
        }
    }

    &:checked ~ .control {
        background-color: $green;
        border-color: $green;
        color: $white;
        font-weight: 700;

        & .iconWrap {
            &::after {
                transform: scale(1);
            }
        }

        & .icon {
            animation-name: checked;
            animation-fill-mode: forwards;
            opacity: 1;
            z-index: 5;
        }
    }

    &:disabled ~ .control {
        cursor: not-allowed !important;
        opacity: 0.5;

        & * {
            pointer-events: none !important;
        }
    }
}

@keyframes checked {
    10% {
        stroke-dashoffset: 0.75em;
    }

    100% {
        stroke-dashoffset: 0;
    }
}
