.wrap {
    display: flex;
    flex-direction: column;
}

.title-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    justify-content: space-between;
}

.title {
    font-size: 2.75rem;
    font-weight: 400;
    margin-bottom: 0.25rem;
}

.title strong {
    font-weight: 400;
    color: #4c76eb;
}

.description {
    color: #b8babe;
}

.image {
    width: 6.625rem;
    margin-right: 0.75rem;
}

@media screen and (max-width: 991px) {
    .title {
        line-height: 2.5rem;
    }
}
