@import '../../../../sass/styles.scss';

.topContent {
    position: relative;
    padding-bottom: 3.125rem;
}

.wrap {
    margin-top: -1.25rem;
    padding-top: 1.25rem;
}

.content {
    max-width: 40rem;
    width: 100%;
    position: relative;
    z-index: 1;
    margin-bottom: 3.75rem;
}

.holder {
    position: relative;
    padding-top: 5.125rem;
}

.subtitle {
    @include helper-text;
}

.mobileImg {
    display: none;
}

.image {
    & img {
        position: absolute;
        top: 10%;
        right: -3.5%;
    }
}

.text {
    @include text-md;
    max-width: 480px;
    color: $white-light;
    margin: 2rem 0 1.625rem 0;
}

.findUs {
    margin-top: 5.25rem;

    & p {
        @include text-small;
        margin-bottom: 1rem;
    }

    & .icons img:nth-of-type(2) {
        margin: 0 2rem;
    }
}

.poweredBy {
    display: flex;
    align-items: center;
    gap: 0.75rem;

    & p {
        color: var(--white-light);

        @include text-small;
    }

    & .icons img:nth-of-type(2) {
        margin: 0 2rem;
    }
}

@include max-w-lg {
    .image img {
        top: 8%;
        max-width: 30rem;
    }

    .image img {
        top: 20%;
    }
}

@include max-w-lg {
    .title {
        @include h1-md;
    }

    .text {
        @include button-text;
    }

    .content {
        max-width: 25rem;
    }

    .image img {
        max-width: 25rem;
    }

    .topContent {
        & :global(.nav-holder) {
            background-color: $blue;
        }
    }
}

@include max-w-sm {
    .findUs {
        display: none;
    }

    .poweredBy {
        display: none;
    }

    .holder {
        padding-top: 40px;
    }

    .image {
        img {
            position: relative;
            max-width: 16.875rem;
            top: 0;
            right: 0;
            margin: 1.5625rem auto 0 auto;
        }
    }

    .image .desktopImg {
        display: none;
    }

    .image .mobileImg {
        display: block;
    }

    .image .singleImage {
        display: block;
    }

    .content {
        max-width: none;
    }

    .text {
        max-width: none;
    }

    .content {
        margin-bottom: 0;
    }
}

@include max-w-xs {
    .title {
        @include h1-sm;
    }

    .text {
        @include text-small;
        margin: 0.75rem 0 0.625rem 0;
    }
}

.secondary {
    padding-bottom: 4rem;

    @include max-w-sm {
        padding-bottom: 0;
    }

    & .holder {
        padding-top: 32px;
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));

        @include max-w-sm {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
    }

    & .content {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    & .image {
        margin-right: -8.48%;

        @include max-w-sm {
            margin-right: 0;
        }

        & img {
            max-width: 100%;
            position: static;
            top: 0;
            right: 0;

            @include max-w-sm {
                max-height: 100%;
                margin: auto;
            }
        }
    }

    & .findUs {
        order: 2;
        grid-column: 2 span;
    }
}

@media screen and (max-width: 829px) {
    .image img {
        top: 40%;
    }
}
