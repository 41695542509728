.body {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
}

.action {
    max-width: 100px;
    width: 100%;
    margin: 12px auto 0;
}