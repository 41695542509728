@import '../../../sass/styles.scss';

.holder {
    background-repeat: no-repeat;
    background-position-y: 0;
    background-position-x: 0;
    height: 40px;
    width: 40px;
    overflow: hidden;
    animation: loader-circle 1s steps(8, end) infinite;
}

@keyframes loader-circle {
  to {
    background-position-x: -320px;
  }
}
