@import '../../sass/styles.scss';

.slider {
    @include gray-bg;
    padding: 80px 0;
    transform: translate(0, 0);
    margin-bottom: 5rem;
    @include max-w-sm {
        padding: 32px 0;
    }

    & .wrapper {
        max-width: 1150px;
        margin: 0 auto;
    }

    & .text {
        @include text-md;
        margin: 0 0 24px 0;
        // font-style: italic;
    }

    & .card {
        max-width: 320px;
    }

    & .nameCol {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    & .user {
        display: flex;

        & .icon {
            width: 48px;
            height: 48px;
            background: rgba(76, 118, 235, 0.1);
            border-radius: 100px;
            color: $blue;
            @include text-icon;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 17px;
        }

        & .name {
            @include button-text;
            margin-bottom: 2px;
        }

        & .time {
            @include text-small;
            color: $dark;
        }
    }
}

.title {
    width: min(100%, 49.5rem);
}

.subtitle {
    @include helper-text;
    margin: 12px 0 40px 0;

    @include max-w-sm {
        display: none;
    }
}

.rating-wrap {
    display: flex;
    align-items: center;
    margin-bottom: 1.25rem;
}

.rating-wrap .rating {
    margin-right: 0.625rem;
}

.content {
    margin-top: 40px;

    @include max-w-sm {
        margin-top: 32px;
    }
}

@media (max-width: 1000px) {
    .slider h2 {
        max-width: 500px;
    }
}

@media (max-width: 650px) {
    .slider {
        & h2 {
            @include h3;
            max-width: unset;
        }

        & .text {
            @include helper-text;
            color: #2e323e;
        }

        & .card {
            max-width: 308px;
        }
    }
}

.navWrap {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @include max-w-sm {
        flex-direction: column;
    }
}

.navigation {
    display: flex;
    align-items: center;
    margin-left: 16px;

    @include max-w-sm {
        margin-top: 16px;
        margin-left: 0;
    }

    & > * {
        &:nth-child(n + 2) {
            margin-left: 8px;
        }
    }
}
