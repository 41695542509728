@import '../../../sass/styles.scss';

.wrap {
    & > *:nth-child(n + 2) {
        margin-top: 16px;
    }
}

.item {
    border-bottom: 1px solid $gray;
    padding-bottom: 16px;

    @include max-w-sm {
        padding-bottom: 12px;
    }

    & h4 {
        @include max-w-xs {
            @include text-icon;
        }
    }
}

.holder {
    padding-bottom: 16px;
    margin-bottom: -16px;

    @include max-w-sm {
        padding-bottom: 12px;
        margin-bottom: -12px;
    }
}

.inner {
    overflow: hidden;
}

.inner:not([disabled]):focus-visible {
    outline: 2px solid $gray;
    outline-offset: 8px;
    border-radius: 2px;
}

@supports not selector(:focus-visible) {
    .inner:not([disabled]):focus {
        outline: 2px solid $gray;
        outline-offset: 4px;
        border-radius: 8px;
    }
}

.header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    cursor: pointer;
    padding: 8px 0;
    margin: -8px 0;
}

.icon {
    flex-shrink: 0;
    margin-left: 6px;
    width: 30px;
    height: 30px;
    will-change: transform;
    transition: transform $transition cubic-bezier(0.45, 0, 0.55, 1);
}

.content {
    @include text-small;
    max-width: 680px;
    color: $dark;
    padding-top: 4px;

    @include max-w-sm {
        max-width: calc(100% - 36px);
    }
}

.item.active .icon {
    transform: rotate(180deg);
}
