@import '../../sass/styles.scss';

.section {
    padding-top: 50px;
    padding-bottom: 100px;

    @include max-w-sm {
        padding-top: 20px;
        padding-bottom: 40px;
    }

    & h2 {
        text-align: center;
    }
}

.subtitle {
    @include helper-text;
    margin: 16px auto 40px auto;
    text-align: center;
    max-width: 580px;

    @include max-w-xs {
        margin: 12px 0 30px;
        @include text-small;
    }
}

.content {
    max-width: 780px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.link {
    color: $blue;
    transition: all 0.3s;

    &:hover {
        color: $green;
    }
}
