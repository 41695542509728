.wrap {
    font-size: 10px;
    max-width: 480px;
    width: 100%;
    border-radius: 2.3em;
    padding: 4em !important;
    background-color: white;
}

.header {
    --gap: 1em;
    --btn-size: 1.4em;

    margin-bottom: 2em;
    display: flex;
    align-items: flex-start;
    gap: 1em;
}

.title {
    flex: 1 1 auto;
    font-size: 2.2em;
    line-height: 1.2em;
    font-weight: 700;
    margin-left: calc((1em + 1.4em) / 2);
}

.btn {
    margin-top: 0.7em;
    flex-shrink: 0;
    width: 1.4em;
    height: 1.4em;
    border: none;
    background: none;
    cursor: pointer;
}

.btn svg {
    width: 100%;
    height: 100%;
}

@media (max-width: 479px) {
    .wrap {
        padding: 2em 1.5em !important;
    }
}
.wrap {
    font-size: 10px;
    max-width: 480px;
    width: 100%;
    border-radius: 2.3em;
    padding: 4em !important;
    background-color: white;
}

.header {
    --gap: 1em;
    --btn-size: 1.4em;

    margin-bottom: 2em;
    display: flex;
    align-items: flex-start;
    gap: 1em;
}

.title {
    flex: 1 1 auto;
    font-size: 2.2em;
    line-height: 1.2em;
    font-weight: 700;
    margin-left: calc((1em + 1.4em) / 2);
}

.btn {
    margin-top: 0.7em;
    flex-shrink: 0;
    width: 1.4em;
    height: 1.4em;
    border: none;
    background: none;
    cursor: pointer;
}

.btn svg {
    width: 100%;
    height: 100%;
}

@media (max-width: 479px) {
    .wrap {
        padding: 2em 1.5em !important;
    }
}
