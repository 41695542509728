.chart-wrap {
    height: 25rem;
    margin-bottom: 1.25rem;
}

.chart {
    background-color: var(--gray-bg);
    max-width: 73.75rem;
    border-radius: 2rem;
    margin: 0 auto;
    width: 100%;
    padding: 1rem 0;
}

.cards-wrap {
    display: flex;
    gap: 1.5rem;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 2.25rem;
}
