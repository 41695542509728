@mixin h1 {
    font-size: 3.75rem;
    line-height: 4.5rem;
}

@mixin h1-md {
    font-size: 2.5rem;
    line-height: 2.5rem;
}

@mixin h1-sm {
    font-size: 1.875rem;
    line-height: 2.5rem;
}

@mixin h2 {
    font-size: 2rem;
    line-height: 2.75rem;
    font-weight: 400;
}

@mixin h3 {
    font-size: 1.5rem;
    line-height: 2.125rem;
}

@mixin h4 {
    font-size: 1.125rem;
    line-height: 1.875rem;
}

@mixin button-text {
    font-weight: 500;
    font-size: 1rem;
    line-height: 150%;

    @include max-w-sm {
        font-size: 0.875rem;
        line-height: 143%;
    }
}

@mixin text-md {
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.875rem;
}

@mixin text-small {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
}

@mixin text-section {
    font-size: 0.875rem;
    line-height: 1.25rem;
}

@mixin text-section-sm {
    font-size: 0.875rem;
    line-height: 1.125rem;
}

@mixin text-icon {
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5rem;
}

@mixin helper-text {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #767b89;
}

@mixin descriptors {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.125rem;
}

@mixin numbers {
    font-weight: 500;
    font-size: 2.75rem;
    line-height: 3.5rem;
}

@mixin numbers-small {
    font-weight: 500;
    font-size: 2.125rem;
    line-height: 2.75rem;
}

@mixin numbers-xs {
    font-weight: 500;
    font-size: 1.875rem;
    line-height: 2.5rem;
}

@mixin max-width-wrapper {
    max-width: max(1120px, 95vw);
}

@mixin max-width-container {
    max-width: max(1318px, 95vw);
}

@mixin gray-bg {
    border-radius: 3rem;
    padding: 5rem 0;
    background: #f9fafb;

    @include max-w-sm {
        border-radius: 30px;
    }
}

@mixin color-bg-md {
    padding: 5rem 0.625rem;
}

@mixin color-bg-sm {
    padding: 2rem 0.9375rem;
}

@mixin blue-bg {
    border-radius: 3rem;
    padding: 3.875rem 0;
    background: #4c76eb;
    color: $white;
}

/* Mq max */

@mixin max-w-xxs {
    @media (max-width: 374px) {
        @content;
    }
}

@mixin max-w-xs {
    @media (max-width: 479px) {
        @content;
    }
}

@mixin max-w-prev-xs {
    @media (max-width: 567px) {
        @content;
    }
}

@mixin max-w-sm {
    @media (max-width: 767px) {
        @content;
    }
}

@mixin max-w-md {
    @media (max-width: 991px) {
        @content;
    }
}

@mixin max-w-lg {
    @media (max-width: 1199px) {
        @content;
    }
}

@mixin max-w-xl {
    @media (max-width: 1365px) {
        @content;
    }
}
