.wrap {
    width: 100%;
}

.wrap .label {
    margin-bottom: 0.25rem;
    color: var(--black);
    font-weight: 400;
    font-size: 0.875rem;
}

.input-wrap {
    display: inline-flex;
    align-items: center;
    border: 1px solid var(--gray);
    border-radius: 1rem;
    overflow: hidden;
    min-width: 0;
    width: 100%;
}

.value-wrap {
    display: flex;
    align-items: center;
    padding: 0.9375rem 1.25rem;
    flex: 1 1 0;
    min-width: 0;
}

.value {
    color: var(--black);
    display: inline-block;
    width: 100%;
    flex: 1 1 0;
    min-width: 0;
}

.value-wrap.placeholder .value {
    color: var(--dark);
}

.trigger {
    display: inline-flex;
    align-items: center;
    flex-shrink: 0;
    padding: 1rem;
    gap: 0.75rem;
    background-color: transparent;
    border: none;
    color: var(--dark);
    outline: none;
    cursor: pointer;
    border-left: 1px solid var(--gray);
    transition: background-color 0.3s;
}

.trigger .icon {
    width: 1.125rem;
    height: 1.125rem;
    color: var(--color-icons-gray-100);
    flex-shrink: 0;
}

.input-wrap:focus-within .trigger,
.trigger:hover {
    background-color: var(--gray-bg-dark);
}

.error-wrap {
    margin-top: 0.125rem;
}

.input-wrap.error {
    border-color: var(--red);
    color: var(--red);
}

.input-wrap.error .trigger {
    border-left-color: var(--red);
}

@media screen and (max-width: 767px) {
    .wrap {
        max-width: 100%;
    }

    .input-wrap {
        flex-direction: column;
    }

    .value-wrap {
        width: 100%;
        padding: 1.5rem;
    }

    .trigger {
        width: 100%;
        justify-content: center;
        padding: 0.75rem;
        border-left: none;
        border-top: 1px solid var(--gray);
    }
}
