@import '../../sass/styles.scss';

.section {
    padding-top: 100px;
    padding-bottom: 50px;
    text-align: center;

    @include max-w-sm {
        padding-top: 40px;
        padding-bottom: 20px;
    }
}

.triple-section-strong {
    font-weight: 700;
    color: var(--green-third);
    white-space: nowrap;
}

.title {
    margin-bottom: 16px;

    @include max-w-sm {
        margin-bottom: 12px;
    }
}

.subtitle {
    @include helper-text;

    @include max-w-sm {
        @include text-small;
    }
}

.holder {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 7.14%;
    margin: 40px auto 24px;
    max-width: 1120px;

    @include max-w-sm {
        max-width: none;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 32px;
        margin: 32px 0 16px;
    }
}

.image {
    max-width: 120px;
    max-height: 80px;
}

.card {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include max-w-sm {
        max-width: 480px;
        width: 100%;
        margin: 0 auto;
    }
}

.name {
    @include h4;
    font-weight: 700;
    color: $black;
    margin: 17px 0 8px;

    @include max-w-sm {
        @include text-icon;
        margin: 14px 0 10px 0;
    }
}

.desc {
    @include text-small;
    color: $dark;
}
