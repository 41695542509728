@import '../../sass/styles.scss';

.root {
    max-width: 380px;
    width: 100%;
    margin: 0 auto;
    position: relative;

    @include max-w-xs {
        max-width: none;
    }

    & input {
        border: 1px solid rgba(255, 255, 255, 0.3);
        border-radius: 16px;
        width: 100%;
        padding: 15px 0 15px 48px;
        font-weight: 400;
        line-height: 20px;
        font-size: 16px;
        outline: none;
        background: inherit;
        color: $black;

        &::placeholder {
            color: rgba(255, 255, 255, 0.7);
        }

        &:focus {
            background: $white;
        }
    }

    & .inputActive {
        border-radius: 16px 16px 0 0;
        border-bottom: 1px solid $gray;
    }

    & .icon {
        position: absolute;
        top: 19px;
        left: 20px;
    }

    & .iconFocused {
        & path {
            fill: black;
        }
    }

    .clearButton {
        color: $blue;
        @include text-small;
        position: absolute;
        right: 20px;
        top: 19px;
        height: 20px;
        cursor: pointer;
        transition: all $transition;

        &:hover {
            text-shadow: 1px 1px 1px $black;
        }
    }
}

.articles {
    position: absolute;
    background: #fff;
    z-index: 999;
    border-radius: 0 0 16px 16px;
    padding: 10px 0;
    width: 100%;
    box-shadow: 0px 2px 10px #c8bfbf;
}

.singleArticle {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 20px;
    position: relative;
    cursor: pointer;

    & img {
        width: 40px;
        height: 40px;
        border-radius: 10px;
    }

    & .text {
        margin-left: 12px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        & p {
            @include text-section;
            font-weight: 500;
            color: $black;
            margin-bottom: 2px;
            text-align: start;
        }

        & span {
            @include descriptors;
            color: $dark;
        }
    }

    &:focus {
        outline: none;
        background: rgba(152, 219, 150, 0.1);
    }

    &:hover {
        background: rgba(152, 219, 150, 0.1);
    }
}

.singleArticle:not(:last-of-type)::after {
    content: '';
    width: 89%;
    height: 1px;
    background: $gray;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
}

.nothing {
    @include text-small;
    color: $black;
    text-align: left;
    padding-left: 20px;
}

@media (max-width: 450px) {
    .root {
        max-width: 310px;
    }
}
