.result-title {
    margin-bottom: 1rem;
}

.score-wrap {
    display: flex;
    justify-content: center;
    margin-bottom: 0.5rem;
}

.footer {
    display: flex;
    justify-content: center;
}

.score-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
}

.card.green .value {
    color: var(--green);
}
