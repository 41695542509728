@import '../../sass/styles.scss';

.h5 {
    margin: 12px 0 40px;

    @include max-w-sm {
        margin: 12px 0 24px;
    }
}

.holder {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 20px;

    @include max-w-sm {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 15px;
    }

    & h4 {
        margin: 8px 0;
        @include h4;
        font-weight: 700;

        @include max-w-sm {
            margin: 4px 0;
        }
    }

    p {
        @include helper-text;
    }
}

.content {
    max-width: 320px;
    width: 100%;

    @include max-w-sm {
        max-width: none;
    }
}

.icon {
    width: 48px;
    height: 48px;

    @include max-w-sm {
        width: 40px;
        height: 40px;
    }
}

.image {
    max-width: 120px;
    width: 100%;
    margin-bottom: 14px;

    @include max-w-sm {
        margin-bottom: 9px;
    }

    & img {
        max-width: 100%;
    }
}

.secondary {
    @include max-w-sm {
        margin-top: 32px;
        gap: 32px;
    }

    p {
        @include text-small;
    }

    & h4 {
        @include max-w-sm {
            margin: 9px 0 7px;
        }
    }
}
