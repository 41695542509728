@import '../../../../sass/styles.scss';

.presentation {
    & .holder {
        display: grid;
        grid-template-columns: minmax(0, 0.566fr) minmax(0, 0.435fr);

        @include max-w-sm {
            grid-template-columns: minmax(0, 1fr);
            gap: 40px;
            padding-top: 40px;
            padding-bottom: 40px;
        }
    }

    & .left {
        padding: 100px 32px 100px 0;
        border-right: 1px solid $gray;

        @include max-w-md {
            padding: 80px 32px 80px 0;
        }

        @include max-w-sm {
            padding: 0;
            border-right: none;
        }
    }

    & .right {
        padding: 122px 0 100px 32px;

        @include max-w-md {
            padding: 92px 0 80px 32px;
        }

        @include max-w-sm {
            padding: 0;
        }

        & .rightSubtitle {
            @include helper-text;
            margin: 16px 0 24px 0;

            @include max-w-sm {
                @include text-small;
                margin: 12px 0 24px 0;
            }
        }

        &Content {
            max-width: 380px;
            width: 100%;
            margin-left: auto;

            @include max-w-sm {
                margin-left: 0;
                max-width: none;
            }
        }
    }

    h2 {
        max-width: 480px;

        @include max-w-sm {
            @include h3;
        }
    }

    .values {
        margin-top: 44px;
        max-width: 540px;
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 240px));
        gap: 32px 60px;

        @include max-w-md {
            gap: 24px;
        }

        @include max-w-sm {
            margin-top: 24px;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            grid-gap: 16px 20px;
        }
    }

    .quantity {
        @include numbers;

        @include max-w-md {
            @include numbers-xs;
        }

        & span {
            color: $blue;
        }
    }

    .helperText {
        @include helper-text;
        margin-top: 4px;

        @include max-w-sm {
            @include text-small;
            margin-top: 4px;
        }
    }
}
