@import '../../sass/styles.scss';

.mark {
    display: flex;
    align-items: center;

    @include max-w-md {
        flex-direction: column;
        align-items: flex-start;
    }
}

.regularText {
    @include h4;
    font-weight: 700;

    @include max-w-md {
        margin-top: 6px;
    }

    @include max-w-sm {
        font-size: 16px !important;
        @include button-text;
    }
}

.helperText {
    @include helper-text;
    color: #2e323e;

    @include max-w-md {
        margin-top: 4px;
    }

    @include max-w-sm {
        @include text-small;
    }
}

.doneImage {
    width: 48px;
    height: 48px;
    flex-shrink: 0;
    margin-right: 12px;

    @include max-w-sm {
        width: 32px;
        height: 32px;
        margin-right: 0;
    }
}

.nopeImage {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    margin-right: 8px;

    @include max-w-sm {
        width: 24px;
        height: 24px;
        margin-right: 0;
    }
}
