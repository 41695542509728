@import '../../../sass/styles.scss';

.btn {
    cursor: pointer;
    position: relative;
    white-space: nowrap;
    font-family: $font-primary;
    font-weight: 500;
    font-size: 18px;
    line-height: 167%;
    color: $black;
    padding: 0;
    outline: none;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    background-color: transparent;
    border: none;
    transition: color 0.15s ease-in-out;

    @include max-w-sm {
        font-size: 16px;
        line-height: 150%;
    }

    &[disabled],
    &.disabled {
        opacity: 0.5;
    }

    & .icon {
        width: 44px;
        height: 44px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $green;
        border-width: 1px;
        border-style: solid;
        border-color: transparent;
        transition:
            border-color 0.15s ease-in-out,
            background-color 0.15s ease-in-out;
    }

    &:not(.reverse) {
        & .icon {
            margin-left: 16px;
        }
    }

    &.reverse {
        flex-direction: row-reverse;

        & .icon {
            margin-right: 16px;
        }
    }

    & svg {
        fill: $white;
        flex-shrink: 0;
        width: 24px;
        height: 24px;
    }

    &:not(:disabled, .disabled):focus-visible,
    &:not(:disabled, .disabled):focus {
        & .icon {
            border-color: #b6f9b4;
        }
    }

    &:not(:disabled, .disabled):focus {
        & .icon {
            border-color: #b6f9b4;
        }
    }

    &:not(:disabled, .disabled):hover {
        & .icon {
            background-color: $green;
        }
    }

    &:not(:disabled, .disabled):active {
        & .icon {
            background-color: #5c9f5a;
        }
    }
}

a.btn {
    justify-content: flex-end;
}
