.title {
    font-size: 48px;
    line-height: 1.2em;
    font-weight: 500;
    font-family: 'Teko', sans-serif;
    color: white;
    text-align: center;
    font-style: italic;
}

.container {
    text-align: center;
}
