@import '../../sass/styles.scss';

.topContent {
    position: relative;
}

.wrap {
    margin-top: -20px;
    padding-top: 20px;
}

.content {
    max-width: 580px;
    width: 100%;
    position: relative;
    z-index: 1;
}

.holder {
    position: relative;
    padding-top: 62px;
}

.subtitle {
    @include helper-text;
}

.mobileImg {
    display: none;
}

.image {
    & img {
        position: absolute;
        top: -2%;
        right: -2%;
    }
}

.text {
    @include text-md;
    max-width: 480px;
    color: $white-light;
    margin: 32px 0 26px 0;

    strong {
        font-weight: inherit;
        color: $white;
    }
}

.findUs {
    margin-top: 84px;

    & p {
        @include text-small;
        margin-bottom: 16px;
    }

    & .icons img:nth-of-type(2) {
        margin: 0 32px;
    }
}

.poweredBy {
    display: flex;
    margin-top: 84px;
    align-items: center;
    gap: 0.75rem;

    & p {
        color: var(--white-light);

        @include text-small;
    }

    & .icons img:nth-of-type(2) {
        margin: 0 32px;
    }
}

@include max-w-lg {
    .image img {
        top: 8%;
        max-width: 480px;
    }
}

@include max-w-lg {
    .title {
        @include h1-md;
    }

    .text {
        @include button-text;
    }

    .content {
        max-width: 400px;
    }

    .image img {
        max-width: 400px;
    }

    .topContent {
        & :global(.nav-holder) {
            background-color: $blue;
        }
    }
}

@include max-w-sm {
    .findUs {
        display: none;
    }

    .poweredBy {
        display: none;
    }

    .holder {
        padding-top: 40px;
    }

    .image {
        img {
            position: relative;
            max-width: 270px;
            top: 0;
            right: 0;
            margin: 25px auto 0 auto;
        }
    }

    .image .desktopImg {
        display: none;
    }

    .image .mobileImg {
        display: block;
    }

    .image .singleImage {
        display: block;
    }

    .content {
        max-width: none;
    }

    .text {
        max-width: none;
    }
}

@include max-w-xs {
    .title {
        @include h1-sm;
    }

    .text {
        @include text-small;
        margin: 12px 0 10px 0;
    }
}

.secondary {
    padding-bottom: 64px;

    @include max-w-sm {
        padding-bottom: 0;
    }

    & .holder {
        padding-top: 32px;
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));

        @include max-w-sm {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
    }

    & .content {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    & .image {
        margin-right: -8.48%;

        @include max-w-sm {
            margin-right: 0;
        }

        & img {
            max-width: 100%;
            position: static;
            top: 0;
            right: 0;

            @include max-w-sm {
                max-height: 100%;
                margin: auto;
            }
        }
    }

    & .findUs {
        order: 2;
        grid-column: 2 span;
    }
}

@media screen and (min-width: 1200px) {
    .image {
        & img {
            position: absolute;
            top: -2%;
            right: -2%;
            height: 100%;
        }
    }
}
