.result-title {
    margin-bottom: 1rem;
    padding: 0 1rem;
}

.description {
    width: min(100%, 48.75rem);
    margin: 0 auto;
}

.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 39.625rem;
    margin: 0 auto;
    padding-top: 2.5rem;
}

.footer .divider {
    margin: 0 1.5rem;
}

.link {
    transition: color 0.3s ease;
}

.link:hover {
    text-decoration: underline;
    color: var(--blue);
}

@media screen and (width <= 768px) {
    .footer {
        flex-direction: column;
    }

    .footer .cta {
        width: 100%;
    }

    .footer .divider {
        margin: 0.5rem;
    }
}
