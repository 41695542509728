@import '../../../../sass/styles';

.container {
    padding-right: unset;
    padding-left: unset;
}

.wrap {
    display: flex;
    gap: 1.25rem;
    justify-content: space-between;
    margin-bottom: 4.75rem;
    @include max-w-sm {
        padding-top: 32px;
        padding-bottom: 32px;
        display: flex;
        flex-direction: column;
    }
}

.cta a {
    margin: 0;
}

.content-left {
    @include gray-bg;
    padding-left: 4.313rem;
    padding-right: 4.313rem;
    flex: 1 1 65%;
}

.content-right {
    text-align: center;
    flex: 1 1 35%;
}

.content-right img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 2.5rem;
}

.title {
    max-width: 30rem;
    margin-bottom: 1rem;
}

.subtitle-wrap {
    margin-bottom: 1.5rem;
}

.subtitle {
    @include helper-text;

    & + & {
        margin-top: 0.5rem;
    }

    @include max-w-xs {
        @include text-small;

        margin: 0.75rem 0 1.5rem;
    }
}

@media screen and (max-width: 767px) {
    .content-left {
        padding: 2rem 1rem;
    }
}

@media screen and (max-width: 990px) {
    .content-right {
        flex-basis: 50%;
    }
}
