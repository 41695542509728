@import '../../sass/styles.scss';

.wrap {
    display: flex;
    min-height: 740px;
    padding-top: 80px;
    padding-bottom: 80px;
    position: relative;
    border-radius: 48px;
    overflow: hidden;
    background-color: hsla(0, 0%, 0%, 0.35);

    @include max-w-md {
        min-height: 640px;
    }

    @include max-w-sm {
        padding-top: 40px;
        padding-bottom: 40px;
        border-radius: 30px;
        min-height: 480px;
    }
}

.holder {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-bottom: 2.5rem;
    margin-top: 3.125rem;

    .title,
    .subtitle {
        text-align: center;
    }

    .subtitle {
        color: $dark;
        max-width: 425px;
    }
}

.image {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1;

    &::before {
        content: '';
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35));
    }

    & > img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.content {
    max-width: 880px;
    width: 100%;

    &,
    & * {
        color: $white;
    }
}

.date {
    @include helper-text;
    color: $white-light;
    margin-top: 16px;

    @include max-w-sm {
        @include text-small;
        margin-top: 12px;
    }
}

.action {
    margin-top: 32px;

    @include max-w-sm {
        margin-top: 20px;
    }
}

.article-header {
    background: #ccc;
    border-radius: 48px;
    padding: 50px 0 300px 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @include max-w-sm {
        border-radius: 30px;
    }

    & h1 {
        color: $white;
        max-width: 880px;
        font-weight: 700;
    }
}

.colBlock {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.colLink:hover {
    text-decoration: underline;
}

.video {
    padding: 50px 0;
    text-align: center;

    h2 {
        @include h2;
        font-weight: 400;
    }

    .subtitle {
        @include helper-text;
        margin: 16px auto 40px auto;
        max-width: 580px;
    }

    & .videoContent,
    & video {
        width: 100%;
        border-radius: 48px;
    }
}

.headerSection {
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 10px;
    padding: 6px 10px;
    @include text-section;
    font-weight: 500;
    color: $white-light;
    display: inline-block;
}

@media (max-width: 1150px) {
    .video {
        padding: 50px 10px;
    }
}

@media (max-width: 500px) {
    .video {
        padding: 20px 10px;

        h2 {
            @include h3;
            max-width: 308px;
            margin: 0 auto;
        }

        .subtitle {
            @include text-small;
            margin: 12px auto 32px auto;
            max-width: 308px;
        }
    }
}

@media (max-width: 650px) {
    .article-header {
        padding: 24px 0 40px 0;
    }

    .back {
        margin: 82px 0 16px 0;
        @include text-icon;
        font-weight: 500;
    }

    .published {
        @include text-small;
        margin: 12px 0 24px 0;
    }

    .buttonsBlock {
        display: grid;
        grid-template-columns: 150px 150px;
        grid-gap: 8px;

        & .button:not(:last-child) {
            margin-right: 0;
        }
    }
}
