@import '../../sass/styles.scss';

.content {
    position: relative;
}

.startPoint {
    fill: $gray;
    width: 11.5px;
    height: 11.5px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

.timeline {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    border-top: 1px solid $gray;
    z-index: 0;
}

.arrow {
    fill: $gray;
    width: 10px;
    height: 16px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.point {
    height: 18px;
    height: 18px;
    cursor: pointer;
    fill: $white;
    stroke: $gray-dark;
    stroke-width: 4;
    transition: stroke $transition;

    @include max-w-sm {
        stroke-width: 3;
    }

    &:hover {
        stroke: $green;
    }
}

.ellipseData {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    &.active {
        & .point {
            stroke: $green;
        }

        & .textBold {
            font-weight: 500;
            color: $black;
        }
    }
}

.year {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: calc(100% - 9px);
    transform: translateX(-50%);

    & p {
        @include helper-text;
        margin-top: 10px;
        margin-left: -4px;
        margin-right: -4px;

        @include max-w-sm {
            @include text-small;
            margin-top: 6px;
        }

        & span {
            display: block;
            text-align: center;
        }
    }
}

.tooltip {
    font-family: $font-primary;
    background-color: $gray-bg;
    border: 1px solid $gray;
    border-radius: 16px;
    padding: 26px 29px;
    font-weight: 400;
    font-size: 16px !important;
    line-height: 150%;
    color: $black;
    max-width: 412px;
    width: 100%;
    text-align: center;

    @include max-w-sm {
        font-size: 14px;
        line-height: 143%;
        padding: 20px 16px;
        max-width: min(412px, calc(100vw - 52px));
    }
}

.tooltipArrow {
    border-bottom: 1px solid $gray;
    border-right: 1px solid $gray;
}
