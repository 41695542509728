@import '../../sass/styles.scss';

footer {
    padding: 32px 0;
    margin: 0 24px;

    @include max-w-sm {
        margin: 0 10px;
    }

    & hr {
        background: $gray;
        height: 1px;
        width: 100%;
        border: none;
        margin: 32px auto;

        @include max-w-sm {
            margin: 24px auto;
        }
    }
}

.grayBg {
    border-radius: 48px;
    background: #f9fafb;
    padding-top: 32px;
    padding-bottom: 20px;
}

.formWrap {
    flex: 0 1 403px;
    min-width: 360px;
    width: 100%;
    margin-left: 16px;

    @include max-w-sm {
        flex: 0 1 auto;
        min-width: auto;
        width: 100%;
        margin-left: 0;
    }
}

.formControl {
    display: flex;
    align-items: center;
    position: relative;

    @include max-w-xs {
        flex-direction: column;
    }
}

.input {
    flex: 1 0 auto;
    min-width: 0;

    @include max-w-xs {
        width: 100%;
    }
}

.button {
    margin-left: 16px;

    @include max-w-xs {
        margin-left: 0;
        width: 100%;
        margin-top: 12px;
    }
}

.error {
    position: absolute;
    top: 100%;
    left: 0;

    @include max-w-xs {
        position: static;
        top: 0;
    }
}

.subscribeBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include max-w-sm {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 16px;
    }
}

.name {
    & h4 {
        @include h4;
        font-weight: 700px;

        @include max-w-sm {
            @include text-icon;
        }
    }

    & p {
        @include text-small;
        color: $dark;
        margin-top: 6px;

        @include max-w-sm {
            @include text-small;
        }
    }
}

.logo {
    display: flex;
    align-items: center;

    @include max-w-xl {
        order: 1;
        width: 100%;
        margin-top: 12px;
    }

    @include max-w-md {
        width: auto;
    }

    @include max-w-prev-xs {
        margin-top: 32px;
        order: 2;
    }

    & svg {
        max-width: 97px;
        flex-shrink: 0;
        margin-right: 16px;
    }

    & p {
        @include text-small;
    }
}

.nav {
    @include text-small;
    color: $dark;

    @include max-w-md {
        width: 100%;
    }

    @include max-w-prev-xs {
        display: flex;
        flex-direction: column;
    }

    & a {
        display: inline-flex;
        color: $dark;
        transition: color $transition;

        &:hover {
            color: $blue-hover;
        }

        &:hover {
            color: $blue-active;
        }

        &:nth-child(n + 2) {
            margin-left: 24px;

            @include max-w-prev-xs {
                margin-left: 0;
                margin-top: 12px;
            }
        }
    }
}

.email {
    & a {
        font-weight: 500;
        font-size: 14px;
        line-height: 143%;
        color: $black;
        transition: color $transition;

        &:hover,
        &:active {
            color: $green;
        }
    }
}

.icons {
    margin-left: 30px;
    display: flex;
    align-items: center;

    @include max-w-prev-xs {
        margin-left: 0;
        margin-top: 16px;
    }

    a {
        display: inline-flex;
        align-items: center;
        flex-shrink: 0;
        color: $blue;
        transition: color $transition;

        &:hover {
            color: $blue-hover;
        }

        &:hover {
            color: $blue-active;
        }

        &:nth-child(n + 2) {
            margin-left: 16px;
        }
    }

    & svg {
        width: 18px;
        height: 18px;
        fill: currentColor;
        flex-shrink: 0;

        @include max-w-prev-xs {
            width: 20px;
            height: 20px;
        }
    }
}

.basement {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include max-w-xl {
        flex-wrap: wrap;
    }

    @include max-w-prev-xs {
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: flex-start;
    }
}

.links {
    display: flex;
    @include text-section;
    font-weight: 500;

    @include max-w-md {
        order: 1;
        margin-top: 12px;
    }

    @include max-w-prev-xs {
        margin-top: 32px;
        flex-direction: column;
    }
}

.version {
    color: $gray-dark;
}
