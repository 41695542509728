@import '../../sass/styles.scss';

.section {
    padding: 80px 0;

    @include max-w-sm {
        padding: 40px 0 32px;
    }

    & h5 {
        display: none;
        margin-top: 13px;
        max-width: 530px;
    }
}

.holder {
    margin-top: 70px;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 70px 22px;

    @include max-w-sm {
        margin-top: 24px;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 32px;
    }
}

.item {
    max-width: 480px;

    @include max-w-sm {
        max-width: none;
    }
}

.image {
    display: block;

    & img {
        max-width: 100%;
        display: block;
    }

    @include max-w-xs {
        &:not(.cardImage) {
            max-width: 205px;
            width: 100%;
        }
    }
}

.content {
    margin-top: 32px;

    @include max-w-sm {
        margin-top: 23px;
    }
}

.desc {
    margin-top: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: $dark;

    @include max-w-sm {
        @include text-small;
        margin-top: 12px;
    }
}

.card {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: $blue;
    border-radius: 32px;
    padding: 56px 48px;
    color: $white;

    @include max-w-md {
        padding: 32px 24px;
    }

    @include max-w-sm {
        padding: 32px 16px;
        margin-top: 5px;
        margin-left: -16px;
        margin-right: -16px;
    }
}

.cardTitle {
    font-weight: 700;
    position: relative;
    z-index: 1;
}

.cardSubtitle {
    margin-top: 12px;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    max-width: 350px;
    width: 100%;
    color: $white-light;
    max-width: 354px;
    position: relative;
    z-index: 1;

    @include max-w-sm {
        @include text-small;
        line-height: 143%;
    }
}

.cardAction {
    margin-top: auto;
    padding-top: 18px;
    position: relative;
    z-index: 1;

    @include max-w-sm {
        padding-top: 10px;
    }
}

.cardImage {
    position: absolute;
    right: 9px;
    bottom: 20px;
    pointer-events: none;

    @include max-w-lg {

        & img {
            max-width: 160px;
        }
    }

     @include max-w-sm {
        bottom: 20px;

        & img {
            max-width: none;
        }
    }

    @include max-w-prev-xs {
        bottom: 15px;

        & img {
            max-width: 123px;
        }
    }
}
