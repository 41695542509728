.wrap {
    --padding-vertical: 6.25rem;

    position: relative;
    padding: var(--padding-vertical) 4.375rem;
    display: flex;
    justify-content: space-between;
}

.stats-wrap {
    flex: 0 1 34.375rem;
}

.title {
    font-weight: 400;
    margin-bottom: 1rem;
}

.title strong {
    font-weight: 600;
}

.subtitle {
    color: var(--dark);
    margin-bottom: 1.5rem;
    line-height: 1.5;
}

.stats-wrap .title {
    margin-bottom: 2.75rem;
}

.stats {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem 3.75rem;
}

.divider {
    display: block;
    width: 1px;
    margin: calc(var(--padding-vertical) * -1) 2rem;
    top: var(--padding-vertical);
    background-color: rgba(225, 225, 225, 1);
}

.video-wrap {
    flex: 0 1 23.75rem;
    padding-top: 1.5rem;
}

.video-wrap .cta {
    display: flex;
    align-items: center;
    background-color: transparent;
}

.cta .image {
    margin-right: 1rem;
}

.cta svg {
    width: revert;
    height: revert;
}

@media screen and (max-width: 1023px) {
    .wrap {
        padding: 2.5rem 1rem;
        flex-direction: column;
    }

    .stats-wrap,
    .video-wrap {
        flex: auto;
    }

    .stats-wrap {
        margin-bottom: 40px;
    }

    .stats-wrap .title {
        margin-bottom: 24px;
    }

    .stats {
        gap: 1.5rem 1.25rem;
    }

    .video-wrap {
        padding: 0;
    }

    .video-wrap .title {
        margin-bottom: 0.75rem;
    }

    .divider {
        display: none;
    }
}

@media screen and (max-width: 769px) {
    .stats {
        grid-template-columns: repeat(1, 1fr);
    }
}
