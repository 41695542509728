@import '../../../sass/styles.scss';

.btn {
    cursor: pointer;
    position: relative;
    white-space: nowrap;
    font-family: $font-primary;
    font-weight: 500;
    font-size: 14px;
    line-height: 143%;
    color: $white;
    padding: 14.2px 20px;
    outline: none;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    user-select: none;
    background-color: transparent;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;

    &:disabled,
    &.disabled {
        opacity: 0.5;
    }

    & svg {
        fill: currentColor;
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        margin-top: -10px;
        margin-bottom: -10px;
        margin-right: -6px;
        margin-left: 10px;
        fill: $green;
        color: $green;
    }

    & .icon {
        will-change: transform;
        transition: transform 0.15s ease-in-out;
    }

    &.primary {
        background-color: $blue;

        &:not(:disabled, .disabled):focus-visible,
        &:not(:disabled, .disabled):focus {
            border-color: $blue-focus;
        }

        &:not(:disabled, .disabled):focus {
            &:not(:focus-visible) {
                border-color: $blue-focus;
            }
        }

        &:not(:disabled, .disabled):hover {
            background-color: $blue-hover;
        }

        &:not(:disabled, .disabled):active {
            background-color: #749eff;
        }
    }

    &.secondary {
        background-color: rgba(255, 255, 255, 0.15);

        &:not(:disabled, .disabled):focus-visible,
        &:not(:disabled, .disabled):focus {
            border-color: rgba(255, 255, 255, 0.15);
        }

        &:not(:disabled, .disabled):focus {
            &:not(:focus-visible) {
                border-color: rgba(255, 255, 255, 0.15);
            }
        }

        &:not(:disabled, .disabled):hover {
            background-color: rgba(255, 255, 255, 0.3);
        }

        &:not(:disabled, .disabled):active {
            background-color: rgba(255, 255, 255, 0.4);
        }
    }

    &.third {
        background-color: transparent;
        border-color: rgba(255, 255, 255, 0.15);

        &:not(:disabled, .disabled):focus-visible,
        &:not(:disabled, .disabled):focus {
            border-color: rgba(255, 255, 255, 0.3);
        }

        &:not(:disabled, .disabled):focus {
            &:not(:focus-visible) {
                border-color: rgba(255, 255, 255, 0.3);
            }
        }

        &:not(:disabled, .disabled):hover {
            background-color: rgba(255, 255, 255, 0.15);
        }

        &:not(:disabled, .disabled):active {
            background-color: rgba(255, 255, 255, 0.3);
            border-color: rgba(255, 255, 255, 0.3);
        }
    }

    &.fourth {
        background-color: $white;
        border-color: transparent;
        color: $black;

        &:not(:disabled, .disabled):focus-visible,
        &:not(:disabled, .disabled):focus {
            border-color: $green;
        }

        &:not(:disabled, .disabled):focus {
            &:not(:focus-visible) {
                border-color: $green;
            }
        }

        &:not(:disabled, .disabled):hover {
            background-color: $green-second;
        }

        &:not(:disabled, .disabled):active {
            background-color: $green;

            & svg {
                fill: $green-third;
                color: $green-third;
            }
        }
    }

    &.sixth {
        background-color: transparent;
        border-color: rgba(76, 118, 235, 0.5);
        color: $blue;

        &:not(:disabled, .disabled):focus-visible,
        &:not(:disabled, .disabled):focus {
            border-color: rgba(76, 118, 235, 0.5);
        }

        &:not(:disabled, .disabled):focus {
            &:not(:focus-visible) {
                border-color: rgba(76, 118, 235, 0.5);
            }
        }

        &:not(:disabled, .disabled):hover {
            background-color: $blue;
            color: $white;
        }

        &:not(:disabled, .disabled):active {
            background-color: $blue-hover;
            color: $white;
        }
    }

    &.md {
        font-weight: 700;
        font-size: 16px;
        line-height: 150%;
        padding: 19.2px 30px;
        border-radius: 20px;

        & svg {
            width: 32px;
            height: 32px;
            margin-right: -4px;
        }
    }

    &.hasIcon {
        &:not(:disabled, .disabled):hover,
        &:not(:disabled, .disabled):active {
            & .icon {
                transform: rotate(45deg);
            }
        }
    }

    &.responsive {
        font-weight: 700;
        font-size: 16px;
        line-height: 150%;
        padding: 19.2px 30px;
        border-radius: 20px;

        & svg {
            width: 32px;
            height: 32px;
            margin-right: -4px;

            @include max-w-sm {
                width: 24px;
                height: 24px;
                margin-right: -6px;
            }
        }

        @include max-w-sm {
            font-weight: 500;
            font-size: 14px;
            line-height: 143%;
            padding: 14.2px 20px;
            border-radius: 16px;
        }
    }
}
