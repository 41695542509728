.wrap {
    display: flex;
    align-items: center;
    width: 23.583rem;
    max-width: 23.583rem;
    padding: 1.5rem 1.5rem;
    border-radius: 1.5rem;
    background-color: var(--gray-bg);
    flex-grow: 1;
    gap: 1rem;
}

.title {
    font-weight: 700;
    margin-bottom: 0.125rem;
}

.subtitle {
    color: var(--dark);
}
