@import '../../../../../../sass/styles.scss';

.wrap {
    display: grid;
    gap: 4px;

    &:not(.top) {
        height: 100%;
    }
}

.item {
    background-color: $gray;
    transition: background-color $transition;

    &.active {
        background-color: $green;
    }
}
