@import '../../sass/styles.scss';

.container {
    max-width: 1318px;
    width: 100%;
    margin: 0 auto;
    padding-left: min(69px, 5.05vw);
    padding-right: min(69px, 5.05vw);

    @include max-w-xs {
        padding-left: 16px;
        padding-right: 16px;
    }
}
