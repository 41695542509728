@import '../../sass/styles.scss';

@counter-style checkboxes {
    system: cyclic;
    symbols: '\2705';
    suffix: ' ';
}

@counter-style locks {
    system: cyclic;
    symbols: '\1F512';
    suffix: ' ';
}

@counter-style pins {
    system: cyclic;
    symbols: '\1F4CC';
    suffix: ' ';
}

@counter-style diamonds {
    system: cyclic;
    symbols: '\1F539';
    suffix: ' ';
}

@counter-style cookies {
    system: cyclic;
    symbols: '\1F36A' '\1F3AF' '\1F4E2';
    suffix: ' ';
}

@counter-style crosses {
    system: cyclic;
    symbols: '\274C';
    suffix: ' ';
}
@counter-style numbers {
    system: cyclic;
    symbols: '\31 \FE0F \20E3' '\32 \FE0F \20E3' '\33 \FE0F \20E3';
    suffix: ' ';
}

.main {
    margin-top: -24px;
    padding-top: 110px;

    @include max-w-sm {
        padding-top: 72px;
        margin-top: -10px;

        & :global(.nav-holder) {
            background-color: $blue;
        }
    }
}

.header {
    background-color: $blue;
    border-radius: 0px 0px 48px 48px;
    padding: 30px 0;
    position: fixed;
    top: 0;
    z-index: 2;
    left: 24px;
    right: 24px;
    will-change: transform;
    transition: transform 0.4s;

    &:global(.hide-header) {
        transform: translateY(calc(-100% - 2px)) !important;
    }

    @include max-w-sm {
        border-radius: 0px 0px 30px 30px;
        padding: 16px 0;
        left: 10px;
        right: 10px;
    }
}

.backdrop {
    display: none;

    @include max-w-sm {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        opacity: 0;
        z-index: -1;
        visibility: hidden;
        display: block;
        background-color: rgba($white, 0.5);
        transition:
            opacity 0.3s 0.1s,
            visibility 0s 0.4s,
            z-index 0s 0.4s;
    }

    &.show {
        opacity: 1;
        z-index: 1;
        pointer-events: auto;
        visibility: visible;
        transition:
            opacity 0.3s 0s,
            visibility 0s 0s,
            z-index 0s 0s;
    }
}

.content {
    margin: 80px auto;
    width: 100%;
    max-width: 780px;

    @include max-w-sm {
        margin: 32px auto;
    }

    & .bold {
        font-weight: 700;
    }

    & .black {
        color: $black;
    }

    & h1 {
        font-weight: 700;
    }

    & h3 {
        margin: 24px 0 16px 0;
        font-weight: 700;
    }

    & h4 {
        margin: 18px 0 32px 0;
        font-weight: 400;
    }

    & h5 {
        margin: 16px 0 8px 0;
        font-weight: 700;
    }

    & p,
    & li {
        @include helper-text;
    }

    & .list[data-list-type='disc'] {
        list-style-type: disc;
    }

    & .list[data-list-type='checkboxes'] {
        list-style-type: checkboxes;
    }

    & .list[data-list-type='locks'] {
        list-style-type: locks;
    }

    & .list[data-list-type='pins'] {
        list-style-type: pins;
    }

    & .list[data-list-type='diamonds'] {
        list-style-type: diamonds;
    }

    & .list[data-list-type='cookies'] {
        list-style-type: cookies;
    }

    & .list[data-list-type='crosses'] {
        list-style-type: crosses;
    }

    & .list[data-list-type='numbers'] {
        list-style-type: numbers;
    }
}

@include max-w-sm {
    .content {
        & h4 {
            margin: 8px 0 0px 0;
        }
    }
}

@include max-w-xs {
    .content {
        & h4 {
            margin: 8px 0 0px 0;
            @include text-icon;
            font-weight: 400;
        }

        & h3 {
            margin: 0;
            @include h3;
        }

        & p {
            @include text-section;
        }
    }
}
