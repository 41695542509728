@import '../../sass/styles.scss';

.root {
    text-align: center;
    position: relative;

    @include max-w-sm {
        & :global(.nav-holder) {
            background-color: $blue;
        }
    }
}

.content {
    padding: 160px 0 193px;

    @include max-w-xs {
        padding: 60px 0 50px;
    }
}

.subtitle {
    margin: 24px 0 40px;
    @include text-md;
    color: $white-light;

    @include max-w-sm {
        margin: 18px 0 28px;
    }

    @include max-w-xs {
        @include text-small;
        margin: 8px 0 10px;
    }
}

.green {
    color: $green;
}

.actions {
    @include max-w-xs {
        margin-top: 24px;
    }

    & > * {
        &:first-child {
            @include max-w-xs {
                display: none;
            }
        }

        &:nth-child(n + 2) {
            display: none;

            @include max-w-xs {
                display: flex;
            }
        }

        &:nth-child(n + 3) {
            @include max-w-xs {
                margin-top: 16px;
            }
        }
    }
}
