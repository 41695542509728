@import '../../sass/styles.scss';

.section {
    padding: 80px 0 40px;

    @include max-w-sm {
        padding: 40px 0;
    }
}

// .subtitle {
//     @include text-md;
//     color: $white-light;
//     margin: 24px 0 40px 0;

//     @include max-w-sm {
//         @include text-small;
//         margin: 12px 0 24px 0;
//     }
// }

.holder {
    margin-top: 40px;
    display: grid;
    grid-template-columns: minmax(0, 0.544fr) minmax(0, 0.456fr);

    @include max-w-sm {
        grid-template-columns: minmax(0, 1fr);
        margin-top: 24px;
        // padding-top: 40px;
        // padding-bottom: 40px;
    }

    @include max-w-sm {
        // padding-top: 40px;
        // padding-bottom: 40px;
    }
}

.left {
    padding-right: min(60px, 4.4vw);
    border-right: 1px solid $gray;

    @include max-w-sm {
        padding-right: 0;
        border-right: none;
    }
}

.right {
    padding-left: min(60px, 4.4vw);

    @include max-w-sm {
        padding-left: 0;
    }
}

.image {
    position: relative;
    height: 240px;
    border-radius: 16px;
    overflow: hidden;
    margin-bottom: 24px;

    & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    & .chip {
        display: none;

        @include max-w-sm {
            display: inline-flex;
            position: absolute;
            bottom: 10px;
            left: 10px;
        }
    }
}

.content {
    max-width: 540px;
    width: 100%;

    h4 {
        font-weight: 700;
        will-change: color;
        transition: color 0.3s;

        @include max-w-sm {
            @include text-icon;
        }

        &:hover {
            color: $dark;
        }
    }
}

.date {
    @include text-small;
    color: $dark;
    margin-bottom: 4px;

    @include max-w-sm {
        @include descriptors;
    }
}

.desc {
    margin: 8px 0 18px 0;

    @include max-w-sm {
        margin: 8px 0 0;
    }

    p {
        @include helper-text;

        @include max-w-sm {
            @include text-small;
        }
    }
}

.chip {
    @include text-section;
    background: $gray-bg;
    border: 1px solid $gray;
    padding: 6px 10px;
    font-weight: 500;
    border-radius: 10px;
    display: inline-flex;
    align-items: center;
}

.item {
    & .chip {
        @include max-w-sm {
            display: none;
        }
    }

    & .image {
        & .chip {
            @include max-w-sm {
                display: inline-flex;
            }
        }
    }

    @include max-w-sm {
        padding-bottom: 24px;
    }
}

.items {
    padding-top: 10px;
    padding-bottom: 10px;

    @include max-w-sm {
        padding-top: 0;
        padding-bottom: 0;
    }

    & > * {
        &:nth-child(n + 2) {
            padding-top: 40px;
            border-top: 1px solid $gray;

            @include max-w-sm {
                padding-top: 24px;
            }
        }

        &:not(:last-child) {
            padding-bottom: 40px;

            @include max-w-sm {
                padding-top: 24px;
                padding-bottom: 24px;
            }
        }

        @include max-w-sm {
            border-top: 1px solid $gray;
        }
    }

    & .chip {
        @include max-w-sm {
            margin-top: 12px;
        }
    }
}
