@import '../../sass/styles.scss';

.divider {
  margin: 40px auto;
  border: 0;
  height: 1px;
  background-color: $gray;
}

.group {
  display: flex;

  & svg {
    margin-right: 10px;
  }

  & .button {
    @include text-section;
    font-weight: 500;
    padding: 10px 20px 10px 16px;
    color: $blue;
    border-radius: 16px;
    border: 1px solid $blue;
    background: $white;
    cursor: pointer;
    transition: all $transition;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      border: 1px solid $green;
    }
  }

  & .button:not(:last-child) {
    margin-right: 8px;
  }
}

@media (max-width: 650px) {
  .group {
    display: grid;
    grid-template-columns: 150px 150px;
    grid-gap: 8px;

    & .button:not(:last-child) {
      margin-right: 0;
    }
  }

}

@media (max-width: 500px) {
  .divider {
    margin: 24px auto;
  }
}

@media (max-width: 350px) {
  .group {
    grid-template-columns: 135px 135px;

    & .button {
      padding: 10px 7px 10px 7px;
    }
  }
}