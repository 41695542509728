@import '../../sass/styles.scss';

.calculator {
    @include blue-bg;
    margin-top: 1.5rem;
    padding-top: 5rem;
    padding-bottom: 4.375rem;
    padding-left: 0;
    padding-right: 0;

    @include max-w-sm {
        border-radius: 1.875rem;
    }
}

.holder {
    display: flex;
}

.image {
    display: flex;
    margin-top: -0.875rem;
    margin-left: 0.9375rem;

    & img {
        position: relative;
        margin: 0 0 auto auto;
        display: block;
        max-width: 100%;
        right: 0;
        top: 0;
    }
}

.title {
    max-width: 28.125rem;
}

.text {
    @include text-md;
    color: $white;
    margin: 2rem 0 1.625rem;
    max-width: 370px;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2.125rem;
}

.helperText {
    color: $white-light;
}

.content {
    flex: 0 1 33.125rem;
    min-width: 22.5rem;
    width: 100%;
}

@include max-w-md {
    .calculator {
        @include color-bg-md;
    }

    .image img {
        margin: auto;
    }
}

@include max-w-sm {
    .calculator {
        @include color-bg-sm;
        margin-top: 0;
        padding-left: 0;
        padding-right: 0;

        & h1 {
            @include h1-sm;
        }
    }

    .text {
        @include text-small;
    }

    .holder {
        flex-direction: column;
    }

    .content {
        all: revert;
    }

    .image {
        margin: 2.1875rem auto 0;
        max-width: 30rem;
    }
}

@include max-w-xs {
    .text {
        @include text-small;
        max-width: none;
        margin: 0.75rem 0 0.625rem 0;
    }
}
