.wrap {
    max-width: 55rem;
    text-align: left;
    animation: fadeIn 0.5s;
}

.option {
    max-width: 17.5rem;
}

.options-wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.25rem;
    max-width: 36.25rem;
    margin-bottom: 2rem;
}

.title {
    margin-bottom: 2rem;
}

.footer {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    gap: 1rem;
}

@media screen and (width <= 768px) {
    .options-wrap {
        display: flex;
        flex-direction: column;
        max-width: 100%;
    }

    .option {
        max-width: 100%;
    }

    .footer {
        flex-direction: column-reverse;
    }

    .footer .cta {
        width: 100%;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
