@import '../../../sass/styles.scss';

.label {
    display: block;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    white-space: nowrap;
    margin-bottom: 4px;
    color: $dark;
}
