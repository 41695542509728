@import '../../../sass/styles.scss';

.holder {
    width: 100%;
    outline: none;
}

.holder {
    &.error {
        & :global(.react-select__control) {
            border-color: $red;
        }
    }

    &:global(.react-select--is-disabled .react-select__control) {
        opacity: 0.5;
        cursor: not-allowed !important;

        & * {
            pointer-events: none !important;
        }
    }

    & :global(.react-select__control) {
        outline: none;
        font: inherit;
        font-family: $font-primary;
        font-size: 14px;
        line-height: 143%;
        color: $black;
        width: 100%;
        background-color: $white;
        box-sizing: border-box;
        padding: 14.2px;
        border-width: 1px;
        border-style: solid;
        border-color: $gray;
        border-radius: 16px;
        box-shadow: none;
        will-change: padding;
        transition: color 0.15s ease-in-out, border-color 0.15s ease-in-out;
    }

    & :global(.react-select__control--is-focused) {
        border-color: $blue;
    }

    & :global(.react-select__control--menu-is-open .react-select__indicator) {
        & svg {
            transform: rotate(180deg);
        }
    }

    & :global(.react-select__placeholder) {
        font-size: 14px;
        line-height: 143%;
        color: $dark;
        opacity: 1;
    }

    & :global(.react-select__value-container),
    & :global(.react-select__input-container),
    & :global(.react-select__single-value) {
        padding: 0;
        margin: 0;
    }

    & :global(.react-select__indicator-separator) {
        display: none;
    }

    & :global(.react-select__indicator) {
        flex-shrink: 0;
        padding: 0;
        margin: 0;

        & svg {
            width: 24px;
            height: 24px;
            margin: -4px 0;
            flex-shrink: 0;
            fill: $gray-dark;
            transition-property: transform;
            transition: transform $transition;
        }
    }
}

.option {
    display: flex;
    align-items: self-start;
}

body :global(.react-select__menu-portal) {
    z-index: 10;
}

body :global(.react-select__menu) {
    margin-top: 4px;
    margin-bottom: 4px;
    border-radius: 16px;
    padding: 4px;
}

body :global(.react-select__menu-list) {
    margin: 0;
    padding: 0;
}

body :global(.react-select__option),
body :global(.react-select__menu-notice--no-options) {
    margin: 0;
    padding: 10px 8px;
    border-radius: 10px;
    font-weight: 400;
    font-size: 14px;
    text-align: left;
    line-height: 143%;
    color: $dark;
    background-color: transparent;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, padding 0.15s ease-in-out;

    & svg {
        position: absolute;
        top: 11px;
        right: 8px;
        width: 18px;
        height: 18px;
        pointer-events: none;
        opacity: 0;
        transform: scale(0.2);
        transition: opacity 0.15s, transform 0.2s;
    }
}

body :global(.react-select__option--is-focused),
body :global(.react-select__option--is-selected) {
    background-color: #f0f1f2;
    color: $black;
    position: relative;
}

body :global(.react-select__option--is-selected) {
    padding-right: 32px;

    & svg {
        opacity: 1;
        transform: scale(1);
    }
}

@media (any-hover: hover) {
    body :global(.react-select__menu-list::-webkit-scrollbar) {
        width: 4px;
    }

    body :global(.react-select__menu-list::-webkit-scrollbar-track) {
        background-color: $white-light;
    }

    body :global(.react-select__menu-list::-webkit-scrollbar-thumb) {
        background-color: $gray-dark;
        outline: 0 solid $gray-dark;
        border-radius: 10px;
        cursor: pointer;
    }
}
