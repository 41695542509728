@import '../../../../sass/styles.scss';

.wrap {
    @include gray-bg;

    @include max-w-sm {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
}

.title {
    margin-bottom: 1rem;
}

.header {
    margin-bottom: 2.5rem;
    width: min(100%, 42.5rem);
}

.subtitle {
    @include helper-text;
}

.images-wrap {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 1.5rem 5rem;
    list-style-type: none;
}
