@import '../../sass/styles.scss';

.partners {
    @include gray-bg;

    & .subtitle {
        @include helper-text;
        margin: 16px 0 40px 0;
        max-width: 680px;
    }

    & .content {
        display: grid;
        grid-gap: 40px 80px;
        grid-template-columns: repeat(auto-fill, minmax(71px, 148px));

        & img {
            width: 100%;
        }
    }
}

@include max-w-md {
    .partners {
        @include color-bg-md;
    }
}

@include max-w-sm {
    .partners {
        @include color-bg-sm;

        & .subtitle {
            @include text-small;
            margin: 12px 0 24px 0;
            max-width: 310px;
        }

        & .content {
            grid-gap: 12px 32px;
            grid-template-columns: repeat(auto-fill, 71px);
        }
    }
}

.doubleSections {
    & > *:nth-child(n + 2) {
        padding-top: 80px;

        @include max-w-sm {
            padding-top: 40px;
        }
    }
}
