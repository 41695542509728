@import '../../sass/styles.scss';

.section {
    @include gray-bg;
    padding-top: 50px;
    padding-bottom: 80px;
    text-align: center;
    position: relative;

    @include max-w-sm {
        text-align: initial;
        padding-top: 24px;
        padding-bottom: 40px;
    }

    @include max-w-sm {
        & :global(.nav-holder) {
            background-color: $gray-bg;
        }
    }
}

.holder {
    margin-top: 80px;

    @include max-w-sm {
        margin-top: 40px;
    }
}

.stepper {
    margin-bottom: 3rem;
}
