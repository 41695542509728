.wrap {
    display: inline-flex;
    align-items: center;
}

.star {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.25rem;
    height: 1.25rem;
    background-color: var(--gray);
}

.star.filled {
    background-color: var(--green);
}

.star .icon {
    width: 0.875rem;
    height: 0.875rem;
    color: var(--white);
}

.star + .star {
    margin-left: 0.125rem;
}
