@import '../../sass/styles.scss';

.hero {
    padding-top: 50px;
    padding-bottom: 80px;
    border-radius: 48px;
    min-height: min(814px, calc(100vh - 24px));
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include max-w-sm {
        padding-top: 24px;
        padding-bottom: 40px;
        border-radius: 30px;
        min-height: 474px;

        & :global(.nav-holder) {
            background-color: $blue;
        }
    }
}

.title {
    color: $white;
    max-width: 880px;
    font-weight: 700;
    margin-top: 16px;
}

.chip {
    @include text-section;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 10px;
    padding: 6px 10px;
    font-weight: 500;
    color: $white-light;
    display: inline-flex;
    align-items: center;
    margin-top: 32px;

    @include max-w-sm {
        margin-top: 24px;
    }
}

.image {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    overflow: hidden;
    border-radius: 48px;

    @include max-w-sm {
        border-radius: 30px;
    }

    &::before {
        content: '';
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35));
    }

    & > img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.date {
    @include helper-text;
    color: $white-light;
    margin-top: 16px;

    @include max-w-sm {
        @include text-small;
        margin-top: 12px;
    }
}
