@import 'variables';
@import 'mixins';

dl,
dt,
dd {
    padding: 0;
    margin: 0;
    border: 0;
    font: inherit;
    font-size: 100%;
    vertical-align: baseline;
}
