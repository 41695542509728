.inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-block: 5rem;
}

.title-wrap {
    margin-top: 2rem;
}

.title-wrap .subtitle {
    margin-top: 0.75rem;
}
