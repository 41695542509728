@import '../../sass/styles.scss';

.card {
    max-width: 380px;

    @include max-w-sm {
        max-width: none;
    }
}

.imageBlock {
    height: 240px;
    position: relative;
    border-radius: 16px;
    overflow: hidden;
    margin-bottom: 24px;

    & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    & p {
        position: absolute;
        bottom: 10px;
        left: 10px;
        @include text-section;
        font-weight: 500;
        padding: 6px 10px;
        background: $white;
        border-radius: 10px;
    }
}

.textBlock {
    max-width: 330px;

    @include max-w-sm {
        max-width: none;
    }

    & .date {
        @include text-small;
        color: #767b89;
    }

    & h4 {
        @include h4;
        font-weight: 700;
        margin: 4px 0 8px 0;
    }

    & .description {
        @include helper-text;
    }
}

.articles {
    @include gray-bg;
}

.subtitle {
    @include helper-text;
    margin: 12px 0 40px 0;
}

.content {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 2.5rem 1.25rem;
    margin-bottom: 2.5rem;
}

.navButtons {
    list-style-type: none;
    padding: 0;
    margin: 20px 0 40px;
    display: flex;
    align-items: center;

    & > *:nth-child(n + 2) {
        margin-left: 8px;
    }

    @include max-w-md {
        padding-left: min(calc(69px + 24px), calc(5.05vw + 24px));
        padding-right: min(calc(69px + 24px), calc(5.05vw + 24px));
        overflow-x: auto;
    }

    @include max-w-sm {
        margin: 16px 0 24px;
        padding-left: min(calc(69px + 10px), calc(5.05vw + 10px));
        padding-right: min(calc(69px + 10px), calc(5.05vw + 10px));
    }

    @include max-w-xs {
        padding-left: 26px;
        padding-right: 26px;
    }
}

.navWrap {
    @include max-w-md {
        width: 100vw;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }
}

.navLink {
    flex-shrink: 0;
    border-radius: 14px;
    background: $gray-bg;
    border: 1px solid $gray;
    padding: 10px 20px;
    @include button-text;
    cursor: pointer;
    transition: all $transition;

    &:hover {
        border: 1px solid $green;
    }
}

.active {
    background: $green;
    color: $white;
}

.footer {
    margin: 0;
    padding: 0;
}

@include max-w-md {
    .articles {
        padding-top: 32px;
        padding-bottom: 32px;

        h2 {
            max-width: 500px;
        }
    }

    .subtitle {
        max-width: 380px;
    }

    .content {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

@include max-w-sm {
    .articles {
        h2 {
            max-width: 375px;
        }
    }

    .subtitle {
        @include text-small;
        max-width: 300px;
        margin: 12px 0 24px 0;
    }

    .content {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}

@include max-w-xs {
    .navLink {
        @include text-small;
        padding: 8px 16px;
        white-space: nowrap;
    }

    .textBlock {
        max-width: 308px;

        & .date {
            @include text-section-sm;
        }

        & h4 {
            @include text-icon;
        }

        & .description {
            @include text-section;
        }
    }
}

.secondary {
    padding: 40px 0;
    background: transparent;

    @include max-w-sm {
        padding: 32px 0 40px;
    }
}
