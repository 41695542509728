.wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.image-wrap {
    width: 100%;
    max-width: 5rem;
    margin-bottom: 3rem;
}

.image-wrap .image {
    display: block;
    max-width: 100%;
    height: auto;
}

.title-wrap {
    text-align: center;
    max-width: 48.75rem;
    width: 100%;
}

.title {
    margin-bottom: 0.25rem;
}

.subtitle {
    margin-bottom: 0.75rem;
}
