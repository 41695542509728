@import '../../sass/styles.scss';

.title {
    @include h1;
    font-weight: 400;
}

.subtitle {
    @include helper-text;
}

.section {
    padding: 3.75rem 0;
}

.subtitle-full-width,
.inner-full-width {
    width: 100%;
    max-width: none;
}

.cta-centered {
    display: flex;
    justify-content: center;
    align-items: center;
}

.sectionTitle {
    font-size: 3.75rem;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 1.25rem;

    @media screen and (max-width: 767px) {
        font-size: 2.5rem;
    }
}

.fullWidth {
    max-width: 100%;
}

.doubleSections {
    &:not(:empty) {
        margin-top: 6.25rem;
        margin-bottom: 20px;

        @include max-w-sm {
            margin-top: 0;
            margin-bottom: 0;
        }
    }
}

.accordion {
    padding: 50px 0 100px 0;

    & .wrapper {
        max-width: 1150px;
        margin: 0 auto;
    }

    & h2 {
        text-align: center;
    }

    & .subtitle {
        @include helper-text;
        margin: 16px auto 40px auto;
        text-align: center;
        max-width: 580px;
    }

    & .link {
        color: $blue;
        transition: all $transition;

        &:hover {
            color: $green;
        }
    }

    & .content {
        max-width: 780px;
        margin: 0 auto;
    }
}

.imageBlock {
    background-color: hsla(0, 0%, 0%, 0.35);
}

.blogThumbnail {
    position: relative;
    display: block;
    transform: scaleX(-1);
}

@media (max-width: 1150px) {
    .presentation {
        padding: 0 10px;

        & .right {
            padding: 122px 0 100px 10px;
        }
    }

    .accordion {
        padding: 50px 15px 100px 15px;
    }
}

@media (max-width: 650px) {
    .accordion {
        padding: 20px 15px 40px 15px;

        & .subtitle {
            @include text-small;
            margin: 12px auto 32px auto;
            max-width: 300px;
        }
    }
}
