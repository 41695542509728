.wrap {
    display: flex;
    position: relative;
    justify-content: center;
    border-radius: 3rem;
    overflow: hidden;
    background-color: var(--gray-bg);
    padding-block: 5rem;
    margin-bottom: 1.875rem;
}

.title {
    font-weight: 400;
    margin-bottom: 0.75rem;
}

.title strong {
    font-weight: 600;
}

.subtitle {
    color: var(--dark);
    font-weight: 400;
}

.inner {
    padding: 0 4.375rem;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    width: 100%;
    z-index: 1;
}

.title-wrap {
    width: 100%;
    margin-bottom: 2.5rem;
}

.services {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    gap: 2.25rem 5rem;
}

@media screen and (max-width: 767px) {
    .wrap {
        padding-block: 2rem;
    }

    .title-wrap {
        margin-bottom: 1rem;
    }

    .title {
        margin-bottom: 0.75rem;
    }

    .subtitle {
        font-size: 0.875rem;
    }

    .inner {
        padding-inline: 1rem;
    }

    .services {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 2rem;
    }
}
