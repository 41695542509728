@import '../../../../sass/styles.scss';

.wrap {
    width: 100%;
    overflow: hidden;
    border-radius: 0.875rem;

    @include max-w-sm {
        border-radius: 0.875rem;
    }
}

.top {
    display: grid;
    gap: 0.25rem;
    width: 100%;
    height: 0.5rem;
}

.step {
    font-size: 1.125rem;
    line-height: 167%;
    color: $black;
}

.text {
    margin-left: 1rem;
    font-size: 1rem;
    line-height: 150%;
    color: $dark;
}

.holder {
    padding: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $white;
}

@media screen and (width <= 768px) {
    .holder {
        flex-direction: column;
        align-items: flex-start;
    }

    .holder .text {
        margin: 0;
    }
}
