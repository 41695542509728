@import '../../sass/styles.scss';

.btn {
    margin: 15px 0 12px;
}

.buttonBlock {
    & p {
        @include descriptors;
    }

    &.white .btn {
        font-weight: 500 !important;
    }
}

.label.white {
    color: $white-light;
}

.label.gray {
    color: $dark;
}
