@import '../../../../sass/styles';

.wrap {
    padding-bottom: 5rem;
}

.header {
    margin-bottom: 2rem;
}

.title {
    margin-bottom: 16px;
    text-align: center;
    @include max-w-sm {
        margin-bottom: 12px;
    }
}

.subtitle {
    @include helper-text;

    text-align: center;
    margin-bottom: 2rem;
    max-width: 48.75rem;
    margin: 0 auto;

    @include max-w-sm {
        @include text-small;
    }
}

.cta {
    display: flex;
    justify-content: center;
}
