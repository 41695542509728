@import '../../../sass/styles.scss';

.error {
    font-family: $font-primary;
    display: inline-flex;
    margin-top: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: $red;
}

.error > span::first-letter {
    text-transform: capitalize;
}
