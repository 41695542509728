@import '../../sass/styles.scss';

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include max-w-lg {
        flex-direction: column;
    }
}

.pane {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 5;

    @include max-w-lg {
        width: 100%;
    }
}

.white {
    color: #fff;
}

.black {
    color: #2e323e;
}

nav {
    display: flex;
    align-items: center;
    gap: 2.5rem;

    @include max-w-xl {
        gap: 2rem;
    }

    @include max-w-lg {
        flex-direction: column;
        flex: 1 0 auto;
        justify-content: center;
        min-height: 0;
    }
}

.navWrap {
    flex: 1 0 auto;
}

.dropDownNav {
    @include max-w-lg {
        max-height: 0;
        width: 100%;
        overflow: hidden;
        transition: max-height 0.65s cubic-bezier(0.35, 0.15, 0.37, 1.14);

        & nav {
            padding-top: 3.75rem;
            padding-bottom: 2.5rem;
        }

        &.isOpen {
            overflow: auto;
            max-height: calc(var(--vh, 1vh) * 100 - 5.125rem);
            transition: max-height 0.65s cubic-bezier(0.35, 0.15, 0.37, 1.14);

            @supports (padding-bottom: env(safe-area-inset-bottom)) {
                --safe-area-inset-bottom: env(safe-area-inset-bottom);

                max-height: calc(var(--vh, 1vh) * 100 - 82px - var(--safe-area-inset-bottom));
            }
        }
    }
}

.navHolder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1 0 auto;
    min-width: 0;

    @include max-w-lg {
        display: flex;
        flex-direction: column;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
    }

    &:not(.dropDownNavHolder) {
        @include max-w-lg {
            visibility: hidden;
            position: fixed;
            padding: 0 1rem;
            border-top: 90px solid transparent;
            border-bottom: 20px solid transparent;
            border-radius: 30px;
            overflow-y: auto;
            left: 10px;
            top: 10px;
            right: 10px;
            opacity: 0;
            z-index: -1;
            transform: scale(0);
            transition:
                opacity 0.5s 0s,
                visibility 0s 0.5s,
                z-index 0s 0.5s,
                transform 0s 0.5s;
            height: min(662px, calc(100vh - 20px));
            max-height: calc(var(--vh, 1vh) * 100 - 20px);

            @supports (padding-bottom: env(safe-area-inset-bottom)) {
                --safe-area-inset-bottom: env(safe-area-inset-bottom);

                height: min(662px, calc(100vh - 20px - var(--safe-area-inset-bottom)));
                max-height: calc(var(--vh, 1vh) * 100 - 20px - var(--safe-area-inset-bottom));
            }

            &.navOpen {
                opacity: 1;
                transform: scale(1);
                transition:
                    opacity 0.6s 0s,
                    visibility 0s 0s,
                    z-index 0s 0s,
                    transform 0.4s cubic-bezier(0.45, 0, 0.55, 1) 0s;
                visibility: visible;
                z-index: 4;

                & + .backdrop {
                    opacity: 1;
                    transform: scale(1);
                    transition:
                        opacity 0.6s 0s,
                        visibility 0s 0s,
                        z-index 0s 0s,
                        transform 0.4s cubic-bezier(0.45, 0, 0.55, 1) 0s;
                    visibility: visible;
                    z-index: 3;
                }
            }
        }
    }
}

.backdrop {
    display: none;

    @include max-w-lg {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        opacity: 0;
        z-index: -1;
        visibility: hidden;
        display: block;
        background-color: $white;
        transform: scale(0);
        transition:
            opacity 0.5s 0s,
            visibility 0s 0.5s,
            z-index 0s 0.5s,
            transform 0s 0.5s;
    }
}

.links {
    display: flex;

    @include max-w-lg {
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-top: 1.25rem;
        padding-bottom: 1px;
    }
}

.logo {
    line-height: 0;

    & a {
        display: inline-flex;
    }

    & img {
        max-width: 8rem;
        margin-right: 3.5rem;

        @include max-w-lg {
            margin-right: 1.875rem;
            max-width: 6.4375rem;
            margin-right: 1.5625rem;
        }

        @media screen and (max-width: 1299px) {
            margin-right: 1.875rem;
        }
    }
}

.navLink {
    @include text-small;
    transition: color $transition;

    &:hover {
        color: $green;
    }

    @include max-w-lg {
        @include h3;
    }

    &.active {
        color: $green;
    }
}

.icons {
    display: flex;
    align-items: center;
    line-height: 0;

    @include max-w-lg {
        justify-content: center;
    }

    & a {
        display: inline-flex;
        align-items: center;
        flex-shrink: 0;
        color: $white;
        transition: $transition;

        &:hover {
            color: $green !important;
        }

        &:nth-child(n + 2) {
            margin-left: 1rem;

            @media screen and (max-width: 1299px) {
                margin-left: 0.5rem;
            }
        }
    }

    & svg {
        width: 1.125rem;
        height: 1.125rem;
        fill: currentColor;
        fill-opacity: 0.7;
        flex-shrink: 0;

        @include max-w-lg {
            width: 1.5rem;
            height: 1.5rem;
        }
    }

    & img {
        @include max-w-lg {
            width: 1.5rem;
        }
    }

    &.iconGray {
        & a {
            color: $gray-dark;
            fill-opacity: 1;
        }
    }

    &.iconWhite {
        color: $white;

        & svg {
            fill-opacity: 0.7;
        }
    }
}

.btn {
    margin-left: 1.875rem;

    @include max-w-xl {
        margin-left: 1rem;
    }

    @include max-w-lg {
        margin-left: 1.5rem;
        margin-top: 1.5rem;
        max-width: 15.6875rem;
        width: 100%;
        margin-left: 0;
    }

    @include max-w-xs {
        max-width: none;
    }
}

.hamburger {
    display: none;

    @include max-w-lg {
        display: block;
    }

    & :global(.hamburger-react) {
        margin: -24.5px -8px -24.5px 0;
    }
}
