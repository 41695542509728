@import '../../../sass/styles.scss';

%placeholder {
    font-size: 14px;
    line-height: 143%;
    color: $dark;
    opacity: 1;
}

.control {
    min-width: 0;

    & svg {
        width: 18px;
        height: 18px;
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translateY(-50%);
        fill: $gray-dark;
        transition: fill 0.15s ease-in-out;
        pointer-events: none;
    }

    & .iconImg {
        pointer-events: none;
        width: 14px;
        display: block;
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translateY(-50%);
    }
}

.holder {
    position: relative;
}

.control {
    & input {
        outline: none;
        font: inherit;
        font-family: $font-primary;
        font-size: 14px;
        line-height: 143%;
        color: $black;
        width: 100%;
        background-color: $white;
        box-sizing: border-box;
        padding: 14.2px;
        border-width: 1px;
        border-style: solid;
        border-color: $gray;
        border-radius: 16px;
        will-change: padding;
        box-shadow: 1px 1000px 1px $white inset;
        transition: color 0.15s ease-in-out, border-color 0.15s ease-in-out, padding 0.15s ease-in-out;

        &:not([disabled]):hover {
            border-color: $gray-2;
        }

        &:not(.error):focus-visible,
        &:not(.error):focus {
            border-color: $blue;

            & ~ svg {
                fill: $blue;
            }
        }

        &:not(.error):focus {
            &:not(:focus-visible) {
                border-color: $blue;

                & ~ svg {
                    fill: $blue;
                }
            }
        }

        &[disabled] {
            opacity: 0.5;
        }

        &.error {
            border-color: $red;

            & ~ svg {
                fill: $red;
            }
        }

        &:-webkit-autofill,
        &:autofill {
            &,
            &:hover,
            &:focus {
                background-color: $white !important;
                background-image: none !important;
            }
        }

        &::placeholder,
        &:-moz-placeholder,
        &::-moz-placeholder,
        &:-ms-input-placeholder,
        &::-webkit-input-placeholder {
            @extend %placeholder;
        }

        &:focus {
            &::placeholder,
            &:-moz-placeholder,
            &::-moz-placeholder,
            &:-ms-input-placeholder,
            &::-webkit-input-placeholder {
                opacity: 0;
                transition: opacity $transition ease;
            }
        }
    }

    &.hasIcon {
        & input {
            padding-left: 44px;

            &[type='email'] {
                padding-left: 48px;
            }
        }
    }
}
