@import '../../../../sass/styles.scss';

.root {
    padding: 3.875rem 0;

    @include max-w-sm {
        padding: 2.5rem 0;
    }
}

.holder {
    display: grid;
    grid-template-columns: minmax(0, 0.53fr) minmax(0, 0.47fr);
    gap: 2rem;

    @include max-w-sm {
        grid-template-columns: minmax(0, 1fr);
        gap: 1.5rem;
    }

    &.positionRight {
        grid-template-columns: minmax(0, 0.47fr) minmax(0, 0.53fr);

        @include max-w-sm {
            grid-template-columns: minmax(0, 1fr);
        }
    }
}

.image {
    display: flex;
    justify-content: center;
    align-items: center;

    @include max-w-sm {
        justify-content: flex-start;
    }

    & img {
        display: block;
        max-width: 100%;

        @include max-w-sm {
            max-width: 30rem;
            width: 100%;
        }

        @include max-w-xs {
            max-width: 12.8125rem;
            width: auto;
        }
    }
}

.wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @include max-w-sm {
        justify-content: flex-start;
    }
}

.content {
    max-width: 30rem;
    width: 100%;

    @include max-w-xs {
        max-width: none;
    }
}

.title {
    margin-top: 1rem;
}

.subtitle {
    margin: 16px 0 9px 0;
    @include helper-text;

    @include max-w-xs {
        margin: 12px 0 8px 0;
        @include text-small;
    }

    &.noBottomOffset {
        margin-bottom: 0;
    }
}

.inner {
    width: 100%;

    @include max-w-sm {
        max-width: none;
    }
}

.positionRight {
    & .wrap {
        order: 1;
        justify-content: flex-start;

        @include max-w-sm {
            order: 2;
        }

        & .inner {
            max-width: none;
        }
    }

    & .image {
        order: 2;

        @include max-w-sm {
            order: 1;
        }
    }
}

.link {
    margin-top: 24px;
}

.secondary {
    & .holder {
        grid-template-columns: repeat(2, minmax(0, 1fr));

        @include max-w-md {
            grid-template-columns: minmax(0, 0.58fr) minmax(0, 0.42fr);
        }

        @include max-w-sm {
            grid-template-columns: minmax(0, 1fr);
        }

        &:not(.positionRight) {
            & .image {
                justify-content: flex-start;
            }

            & .wrap {
                justify-content: flex-start;
                padding-left: 10%;

                @include max-w-md {
                    padding-left: 0;
                }
            }

            & .inner {
                max-width: none;
            }
        }

        &.positionRight {
            grid-template-columns: minmax(0, 0.42fr) minmax(0, 0.58fr);

            @include max-w-sm {
                grid-template-columns: minmax(0, 1fr);
            }
        }
    }
}
