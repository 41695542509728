@import './sass/styles.scss';
@import './styles/index.css';

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    font-family: $font-primary;
    font-style: normal;
    // color: #202024;
    color: $black;
}

h1,
h2,
h3,
h4,
h5,
p,
a {
    padding: 0;
    margin: 0;
}

a {
    text-decoration: none;
    color: inherit;
}

.container {
    max-width: 1300px;
    margin: 0 auto;
}

.wrapper {
    max-width: 1180px;
    margin: 0 auto;
}

html,
body,
#root {
    height: 100%;
}

#root {
    display: flex;
    flex-direction: column;
}

main {
    position: relative;
    padding-top: 24px;
    padding-left: 24px;
    padding-right: 24px;
    flex: 1 0 auto;
    min-height: 0;

    @include max-w-lg {
        padding-top: 10px;
        padding-left: 10px;
        padding-right: 10px;
    }
}

.blueBlock {
    background-color: $blue;
    border-radius: 48px;
    color: $white;
    padding: 50px 0 70px 0;

    @include max-w-lg {
        border-radius: 30px;
    }
}

h1,
.h1 {
    font-size: 60px;
    line-height: 72px;
    font-weight: 400;
    letter-spacing: -0.02rem;
}

h2,
.h2 {
    font-size: 32px;
    line-height: 44px;
    font-weight: 400;
    letter-spacing: -0.01rem;
}

h3,
.h3 {
    font-size: 24px;
    line-height: 34px;
    font-weight: 400;
    color: $black;
}

h4,
.h4 {
    font-size: 18px;
    line-height: 30px;
}

h5,
.h5 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    margin: 0;
}

.h2-subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #767b89;
}

@media (max-width: 1150px) {
    .wrapper {
        padding: 0 10px;
    }

    .blueBlock {
        padding: 25px 0 30px 0;
    }
}

@media (max-width: 950px) {
    h1 {
        font-size: 40px;
        line-height: 40px;
    }
}

@media (max-width: 700px) {
    h2 {
        font-size: 24px;
        line-height: 34px;
    }
}

@media (max-width: 650px) {
    .wrapper {
        padding: 0 15px;
    }

    .h2-subtitle {
        font-size: 14px;
        line-height: 20px;
    }

    h3 {
        font-size: 20px;
        line-height: 30px;
    }
}

@media (max-width: 450px) {
    h1 {
        font-size: 30px;
        line-height: 40px;
    }
}

// doublesection
.positionRight {
    flex-direction: row-reverse;
    justify-content: center;
}

@media (max-width: 850px) {
    .top-content {
        max-width: 308px;
    }
}

@media (max-width: 650px) {
    .top-content {
        margin: 0 auto;
    }
}

.o-hidden {
    overflow: hidden;
}

.o-x-hidden {
    overflow-x: hidden;
    overflow-y: visible;
}

a,
button,
input,
textarea {
    &[disabled],
    &.disabled {
        cursor: not-allowed !important;

        & * {
            pointer-events: none !important;
        }
    }
}

button {
    background-color: transparent;
    color: inherit;
    padding: 0;
    border: none;
    cursor: pointer;
}

.scrollbar-hidden {
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
}

.back-link {
    display: flex;
    align-items: center;
    font-size: 18px;
    line-height: 167%;
    font-weight: 500;
    color: $white-light;
    transition: color 0.15s ease-in-out;

    @include max-w-sm {
        font-size: 16px;
        line-height: 150%;
    }

    & svg {
        fill: currentColor;
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        margin-right: 4px;
    }

    &:not([disabled]):hover,
    &:not(.disabled):hover {
        color: $green;
    }

    &:not([disabled]):active,
    &:not(.disabled):active {
        color: $green;
    }
}

.lock-scroll-y {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: scroll;
}

.o-hidden {
    overflow: hidden;
}

input[type='checkbox'],
input[type='radio'],
input[type='file'] {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
}

.flex-auto {
    flex: 1 0 auto;
    min-width: 0;
}

.block {
    display: block;
}

.w-full {
    width: 100%;
}

.text-initial {
    text-align: initial;
}
